import React, {Component} from 'react';
import Auxi from '../../hoc/Auxi';
import {  Redirect } from 'react-router-dom';
import LayoutLandingPage from '../../Components/Layout/LayoutLandingPage';
import ForgetPassword from '../../Components/ForgotPassword/ForgetPassword';
import axios from '../../axios';
import apiRouter from '../../apiRoute';
import Modal from '../../Components/UI/Modal/Modal';
import ModalContent from '../../Components/UI/Modal/ModalContent';
import { css } from 'react-emotion';
import { RingLoader } from 'react-spinners';
import getSession from '../../Components/UI/Session/getSession';
import {successMessage, failedMessage} from '../../Message';

const override = css`
    display: block;
    margin: 300px auto;
    border-color: red;
    z-index: 1`;


class ForgetPasswordManager extends Component{

    state={
        loading: false,
        forgetPassword: {
            username: '',
        },
        error: {
            userNameError: 'has-error',
            userNameErrorMessage: 'User Name Required',
        },
        redirectToReferrer: false,
        axiosStatus: {
            status: '',
            title: '',
            details: ''
        },
        modal: false
    };

    validateHandler= (fieldName, value) => {
        let errorState = this.state.error;

        switch(fieldName){
            case "username":

                if(value.length < 2){
                    errorState.userNameErrorMessage = 'User Name Must Have 2 Characters';
                    errorState.userNameError = 'has-error';
                }else{
                    errorState.userNameErrorMessage = '';
                    errorState.userNameError = '';

                }
                break;
            default:
                break;
        }

        this.setState({error: errorState})

    };

    spinnerHandler = ()=>{
        this.setState({loading: !this.state.loading})
    };

    modalHandler = ()=> {
        this.setState({modal: !this.state.modal});
    };

    axiosStatusHandler = (status, title, details)=>{
        let oldState = this.state.axiosStatus;
        oldState.status = status;
        oldState.title = title;
        oldState.details = details;
        this.setState({axiosStatus: oldState});
    };
    stateChangeHandler = (e, i)=>{
        let oldState = {...this.state.forgetPassword};
        let newState = e.target.value;
        oldState[i] = newState;
        this.setState({forgetPassword: oldState},
            ()=>{this.validateHandler(i, newState)});
    };


    pageHandler = (data) => {
        if(data.status){
            this.setState({redirectToReferrer : true });
        }
    };

    sendEmailHandle = ()=>{
        let tokenType = getSession('token_type');
        let authToken = getSession('authToken');
        let authorization = { Authorization: ` ${tokenType} ${authToken}` };
        this.spinnerHandler();
        axios.post(apiRouter.resetPassword.create, this.state.forgetPassword,{headers: authorization})
            .then(res=>{
            if(res.status === 200){
                if(res.data.status === 200){
                    this.spinnerHandler();
                    this.setState({modal: true});
                    this.axiosStatusHandler(res.data.status, res.data.title, successMessage.forgetPasswordSuccess);
                    this.pageHandler(res.data);
                }else{
                    this.spinnerHandler();
                    this.setState({modal: true});
                    this.axiosStatusHandler(res.data.status, res.data.title, res.data.details);
                }
            }else{
                this.spinnerHandler();
                this.setState({modal: true});
                this.axiosStatusHandler(res.data.status, 'Error', failedMessage.httpError);
            }
        });
    };


    render(){
        if(this.state.loading){
            return(<RingLoader
                className={override}
                sizeUnit={"px"}
                size={150}
                color={'#E23714'}
                loading={this.state.loading}
            />)
        }else if(this.state.redirectToReferrer){
            const message_body = 'An Email Has Been Sent TO Your Email. Click That Mail To Change Password';
            const message_data = 'For Any Kind OF Query Call Support';
            const path_message = 'I already have a membership! Sign IN Now';
            const path_name = '/signin';

            const { from } =   { from : {pathname: '/Message',
                state:{from: this.props.location.state,
                    message_body: message_body, message_data: message_data,userID: this.state.userId,
                    path_message: path_message, path_name: path_name}}};
            return <Redirect to={ from }/>;

        }else{
            return (
                <Auxi>
                    <Modal show={this.state.modal} modalClosed={this.modalHandler}>
                        <ModalContent
                            status = {this.state.axiosStatus.status}
                            title = {this.state.axiosStatus.title}
                            details = {this.state.axiosStatus.details}
                        />
                    </Modal>
                    <LayoutLandingPage  TitleEnd="Forget Password" TitleStart="" boxClass="login-box">
                        <ForgetPassword
                            errorMessage={this.state.error}
                            sendUserName={this.sendEmailHandle}
                            stateChangeHandler= {this.stateChangeHandler}

                        />
                    </LayoutLandingPage>
                </Auxi>
            );

        }
    }

}

export default ForgetPasswordManager;