import React, {Component} from 'react';
import BotFooter from '../UI/Footer/BotFooter';
import HeaderTop from '../UI/Header/HeaderBot'
import SiteLogo from '../../asstes/images/sohoj-bd-new.jpg';


class LayoutBot extends Component  {

    render() {


        return (
            <div className="sidebar-mini" style={{'fontSize': '.75rem'}}>
                <div className="wrapper" >
                    {/*<HeaderTop LogoPath={SiteLogo}/>*/}
                    <div className={this.props.boxClass}>
                        {this.props.children}
                    </div>
                    <BotFooter/>
                </div>
            </div>
        );

    }
}

export default LayoutBot;