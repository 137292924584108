import React , {Component} from 'react';
import Auxi from '../../hoc/Auxi';


class LayoutLandingPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true
        }
    }
    componentDidMount(){
           // document.body.className= "hold-transition register-page";
            document.body.className= "hold-transition";

    }


   render() {
       return (
           <Auxi>
               <div className={this.props.boxClass}>
                   <div className="login-logo">
                       <div className="card card-success" style={{"marginTop" : "20px"}}>
                           <div className="card-header" >
                               <h3 className="card-title">{this.props.TitleEnd}</h3>
                           </div>
                       </div>
                       {/*<div><b>{this.props.TitleStart} </b> {this.props.TitleEnd} </div>*/}
                   </div>
                   {this.props.children}
               </div>
           </Auxi>
       );
   }

}

export default LayoutLandingPage;