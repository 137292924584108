import React , {Component} from 'react';
import {  Redirect } from 'react-router-dom';
import Auxi from '../../hoc/Auxi';
import LayoutLandingPage from '../../Components/Layout/LayoutLandingPage';
import OtpVerification from '../../Components/Otp/OtpVerification';
import axios from '../../axios';
import apiRoute from '../../apiRoute';
import createSession from '../../Components/UI/Session/createSession';
import Modal from '../../Components/UI/Modal/Modal';
import ModalContent from '../../Components/UI/Modal/ModalContent';
import { css } from 'react-emotion';
import { RingLoader } from 'react-spinners';
import getSession from '../../Components/UI/Session/getSession';
import {successMessage, failedMessage} from '../../Message';

const override = css`
    display: block;
    margin: 300px auto;
    border-color: red;
    z-index: 1`;

class OtpManager extends Component{
    state = {
        loading: false,
        otpVerify: {
            purpose: apiRoute.otpPurpose.login,
            otp: '',
            userId: ''
        },
        redirectToReferrer: false,
        axiosStatus: {
            status: '',
            title: '',
            details: '',
        },
        modal: false
    };

    modalHandler = ()=> {
        this.setState({modal: !this.state.modal});
    };

    axiosStatusHandler = (status, title, details)=>{
        let oldState = this.state.axiosStatus;
        oldState.status = status;
        oldState.title = title;
        oldState.details = details;
        this.setState({axiosStatus: oldState});
    };


    loginHandler = () => {
        this.setState({redirectToReferrer : true })
    };

    spinnerHandler = ()=>{
        this.setState({loading: !this.state.loading})
    };

    verify = ()=>{
        let tokenType = getSession('token_type');
        let authToken = getSession('authToken');
        let authorization = { Authorization: ` ${tokenType} ${authToken}` };
        this.spinnerHandler();
        axios.post(apiRoute.otp,this.state.otpVerify, {headers: authorization}).then(res => {
            if(res.status === 200){
                    if(res.data.status === 200 && createSession(res.data.data[0])){
                        this.axiosStatusHandler(res.data.status, res.data.title, successMessage.otpVerificationSuccess);
                        this.spinnerHandler();
                        this.loginHandler();
                    }else{
                        this.spinnerHandler();
                        this.axiosStatusHandler(res.data.status, res.data.title, res.data.details);
                        this.setState({modal: true});
                    }
                }else{
                    this.spinnerHandler();
                    this.axiosStatusHandler(res.data.status, res.data.title, failedMessage.httpError);
                    this.setState({modal: true});

                }
        });
    };



    stateChangeHandler = (event, index) =>{

        let newState = {
            ...this.state.otpVerify[index]
        };

        newState = event.target.value;
        const oldState = {
            ...this.state.otpVerify
        };

        oldState[index] = newState;
        oldState['userId'] = this.props.location.state.userID;
        this.setState({otpVerify: oldState});
    };

    render(){
        let pathRoute = '';

        if(this.props.location.state){
            if(this.props.location.state.from){
                pathRoute = this.props.location.state.from.from.pathname;
            }else{
                pathRoute = '/dashboard';
            }
        } else{
             pathRoute = '/dashboard';
        }
        const { from } = { from : {pathname: pathRoute }};

        if(this.state.loading){
            return(<RingLoader
                className={override}
                sizeUnit={"px"}
                size={150}
                color={'#E23714'}
                loading={this.state.loading}
            />)
        }else if(this.state.redirectToReferrer){
            return <Redirect to={ from }/>
        }else {
            return(

                <Auxi>
                    <Modal show={this.state.modal} modalClosed={this.modalHandler}>
                        <ModalContent
                            status = {this.state.axiosStatus.status}
                            title = {this.state.axiosStatus.title}
                            details = {this.state.axiosStatus.details}
                        />
                    </Modal>

                    <LayoutLandingPage  TitleEnd="Otp Verification" TitleStart="" boxClass="login-box">
                        <OtpVerification stateChangeHandler={this.stateChangeHandler} verify={this.verify}/>
                    </LayoutLandingPage>
                </Auxi>
            );

        }
    }
}


export default OtpManager;

