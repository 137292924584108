import React from 'react';

const ModalContentImage = (props)=>{

        return (
            <div style={{textAlign: 'center'}}>
                    <img src={props.details} alt="deposit" height="100%" width="100%" />

            </div>
        )

};

export default ModalContentImage;