import React , {Component} from 'react';
import { Redirect } from 'react-router-dom';
import Auxi from '../../hoc/Auxi';
import Modal from '../../Components/UI/Modal/Modal';
import ModalContent from '../../Components/UI/Modal/ModalContent';
import { css } from 'react-emotion';
import { RingLoader } from 'react-spinners';
import LayoutLandingPage from "../../Components/Layout/LayoutLandingPage";
import ResetPassword from '../../Components/ResetPassword/ResetPassword';
import getSession from '../../Components/UI/Session/getSession';
import axios from '../../axios';
import apiRoute from '../../apiRoute';
import destroySession from '../../Components/UI/Session/destroySession';
import { failedMessage} from '../../Message';

/*const { Toolbar, Data: { Selectors } } = require('react-data-grid-addons');*/

const override = css`
    display: block;
    margin: 300px auto;
    border-color: red;
    z-index: 1`;


class ResetPasswordManager extends Component{

    constructor(props, context) {
        super(props, context);
        this.authData = JSON.parse(sessionStorage.getItem('authData'));
        this.state = {
            userId: this.authData.userId,
            sessionId: this.authData.sessionId,
            redirectTrue: false,
            password:{
                oldPassword: '',
                newPassword: '',
                retypePassword: '',
            },
            error: {
                oldPasswordError: 'has-error',
                oldPasswordErrorMessage: 'Password Required',
                newPasswordError: 'has-error',
                newPasswordErrorMessage: 'Password Required',
                retypePasswordError: 'has-error',
                retypePasswordErrorMessage: 'Password Does Not Match',
            },
            axiosStatus: {
                status: '',
                title: '',
                details: '',
                data: ''
            },
            redirectToReferrer: false,
            modal: false,
            loading: false,
        };
    }


    /*
    ***********************************************Common Handler For Every Page Start *****************************************
    */

    // Field Validation Handler Start
    validateHandler= (fieldName, value) => {


        let errorState = this.state.error;
        switch(fieldName){

            case "oldPassword":

                if(value.length < 6){
                    errorState.oldPasswordErrorMessage = 'Password Must Have 6 Characters';
                    errorState.oldPasswordError = 'has-error';
                }else{
                    errorState.oldPasswordErrorMessage = '';
                    errorState.oldPasswordError = '';
                }
                break;
            case "newPassword":
                if(value.length < 6){

                    errorState.newPasswordErrorMessage = 'Password Must Have 6 Characters';
                    errorState.newPasswordError = 'has-error';
                }else if(this.state.password.retypePassword !== value && this.state.password.retypePassword !== ''){
                    errorState.newPasswordErrorMessage = 'Password Does Not Match';
                    errorState.newPasswordError = 'has-error';
                    errorState.retypePasswordErrorMessage = 'Password Does Not Match';
                    errorState.retypePasswordError = 'has-error';
                }else{
                    errorState.newPasswordErrorMessage = '';
                    errorState.newPasswordError = '';
                    if(this.state.password.retypePassword === value){
                        errorState.retypePasswordErrorMessage = '';
                        errorState.retypePasswordError = '';
                    }

                }
                break;
            case "retypePassword":

                if(this.state.password.newPassword !== value){
                    errorState.retypePasswordErrorMessage = 'Password Does Not Match';
                    errorState.retypePasswordError = 'has-error';
                }else{
                    errorState.retypePasswordErrorMessage = '';
                    errorState.retypePasswordError = '';
                    if(this.state.password.newPassword.length > 6){
                        errorState.newPasswordErrorErrorMessage = '';
                        errorState.newPasswordErrorError = '';
                    }
                }
                break;
            default:
                break;
        }
        this.setState({error: errorState});

    };

    //Field Validation End


    //Modal Handler Start
    modalHandler = ()=> {
        this.setState({modal: false});
    };
    //Modal Handler End

    //Spinner Handler Start
    spinnerHandler = ()=>{
        this.setState({loading: !this.state.loading})
    };
    //Spinner Handler End

    //Axios Alert Handler Start
    axiosStatusHandler = (status, title, details, data)=>{
        let oldState = this.state.axiosStatus;
        oldState.status = status;
        oldState.title = title;
        oldState.details = details;
        oldState.data = data;
        this.setState({axiosStatus: oldState});
    };
    //Axios Alert Handler End


    stateHandler = (e, i)=>{
        let newState = e.target.value;
        let oldState = {...this.state.password};
        oldState[i] = newState;
        this.setState({password : oldState},
            ()=> {this.validateHandler(i, newState)});
    };


    /*
     ***********************************************Common Handler For Every Page End *****************************************
     */


    /*
    ***********************************************************Custom Function Start*****************************************
     */


    /************Update Selected Users Info Start**********/

    updateSelectedUser = (e, type, v)=>{
        let axiosData = {
            userId: this.state.userId,
            sessionId: this.state.sessionId,
            oldPassword: this.state.password.oldPassword,
            newPassword: this.state.password.newPassword,
        };

        let tokenType = getSession('token_type');
        let authToken = getSession('authToken');
        let authorization = { Authorization: ` ${tokenType} ${authToken}` };
        axios.post(apiRoute.changePassword,axiosData,{headers: authorization}).then(res => {
            if(res.status === 200){
                if(res.data.status === 200){
                    this.setState({redirectTrue : true })
                }else{
                    this.axiosStatusHandler(res.data.status, res.data.title, res.data.details, '');
                    this.setState({modal: true});

                    if(res.data.code === '12')
                        setTimeout( ()=>{
                            this.setState({redirectToReferrer: 2});
                        },500);
                }
            }else{
                this.axiosStatusHandler(res.data.status, 'Error', failedMessage.httpError, '');
                this.setState({modal: true});
            }
        });
    };

    /*******************Update Selected Users Info end******************/

    /*
    *************************************************Custom Function End***********************************************
     */


    render(){

        if(this.state.loading){
            return(<RingLoader
                className={override}
                sizeUnit={"px"}
                size={150}
                color={'#E23714'}
                loading={this.state.loading}
            />)
        }else if(this.state.redirectToReferrer === 2) {
            if (destroySession())
                return <Redirect to={'/signin'}/>
        }else if(this.state.redirectTrue){
            const { from } =   { from : {pathname: '/sign-out', state:{}}};
            return <Redirect to={ from }/>
        }else {
            return (
                <Auxi>

                            <Modal show={this.state.modal} modalClosed={this.modalHandler}>
                                <ModalContent
                                    status={this.state.axiosStatus.status}
                                    title={this.state.axiosStatus.title}
                                    details={this.state.axiosStatus.details}
                                />
                            </Modal>
                    <LayoutLandingPage TitleEnd="Reset Password" TitleStart="" boxClass="login-box">

                            <ResetPassword
                                stateProductHandler = {this.stateProductHandler}
                                updateSelectedUser = {this.updateSelectedUser}
                                errorMessage = {this.state.error}
                                stateHandler = {this.stateHandler}
                                changePreviewStatus={this.state.changePreviewStatus}
                                handleChangePreviewStatus={this.handleChangePreviewStatus}
                                selectedUser={this.state.selectedUser}
                                availableRoles = {this.state.availableRoles}
                                productAuthentication = {this.productAuthentication}
                            />

                    </LayoutLandingPage>
                </Auxi>
            );
        }
    }
}


export default ResetPasswordManager;