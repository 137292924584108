import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import destroySession from '../../Components/UI/Session/destroySession';
import axios from '../../axios';
import apiRoute from '../../apiRoute';
import getSession from '../../Components/UI/Session/getSession';
import { css } from 'react-emotion';
import { RingLoader } from 'react-spinners';

const override = css`
    display: block;
    margin: 300px auto;
    border-color: red;
    z-index: 1`;


class SignOutManager extends Component{

    constructor(props){
        super(props);
        this.sessionData = getSession('sessionData');
        this.state = {
            axiosData: {
                sessionId: this.sessionData.sessionId,
                userId: this.sessionData.userId,
            },
            loading: false,
            logoutResponse : false
        };

    }

    componentWillMount = ()=>{
        this.destroySessionData();
    };


    spinnerHandler = ()=>{
        this.setState({loading: !this.state.loading})
    };

    destroySessionData = ()=>{
        let tokenType = getSession('token_type');
        let authToken = getSession('authToken');
        let authorization = { Authorization: ` ${tokenType} ${authToken}` };
      /*  this.spinnerHandler();*/
        axios.post(apiRoute.signOut,this.state.axiosData,{headers: authorization})
            .then(res => {
                if(res.data.status === 200 || res.data.code === '12'){
                   /* this.spinnerHandler();
                    this.setState({logoutResponse: true})*/
                }
            });
    };
    render = ()=>{
 /*       if(this.state.loading){
            return(<RingLoader
                className={override}
                sizeUnit={"px"}
                size={150}
                color={'#E23714'}
                loading={this.state.loading}
            />)
        }else if(this.state.logoutResponse){*/
            if(destroySession())
            return <Redirect to={ '/signin' }/>
   /*     }else{
            return '';
        }
   */ }


}

export default SignOutManager;