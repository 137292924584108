import React from "react";
import {Link} from 'react-router-dom';

const ForgetPasseord = (props) => {
    let btnDisable = '';
    (props.errorMessage.userNameError) ? btnDisable = "disabled" : btnDisable = "";

    return (
        <div className="card">
            <div className="card-body login-card-body">
                <form action="" method="post" autoComplete="off">
                    <div className={"form-group "+ props.errorMessage.userNameError ? props.errorMessage.userNameError : '' +"has-feedback"}>
                    <span className="fas fa-envelope form-control-feedback"></span>
                        <input onChange={(event) => {props.stateChangeHandler(event, 'username')}} type="text" className="form-control" placeholder="User Name" />
                        {<span className="help-block">
                                        <strong> {props.errorMessage.userNameErrorMessage }</strong>
                                            </span> }
                    </div>

                    <div className="row">
                        <div className="col-lg-5 col-8" style={{paddingTop: '20px'}}>
                            <button onClick={props.sendUserName} disabled={btnDisable} type="button" className="btn btn-primary btn-block btn-flat">Send Now</button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-12">
                                 <Link to={'/signin'} href="javascript:void(0)">I already have a membership</Link>

                        </div>
                    </div>
                </form>

            </div>

        </div>
    );
}

export default ForgetPasseord;