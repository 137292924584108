import React, { Component } from 'react';
import {  Switch, withRouter,Route } from 'react-router-dom';
import Loadable from 'react-loadable';
import axiosAuth from './axiosAuth';
import apiRoute from './apiRoute';
import { css } from 'react-emotion';
import { RingLoader } from 'react-spinners';
import './App.css';

import PrivateRouteAuth from './hoc/PrivateRouteAuth';
import PrivateRouteNotAuth from './hoc/PrivateRouteNotAuth';

import ForgetPasswordManager from './Containers/LandingPage/ForgetPasswordManager';
import SignInManager from'./Containers/LandingPage/SignInManager';
import MessageManager from './Containers/LandingPage/MessageHandler';
import SignOutManager from'./Containers/LandingPage/SignOutManager';
import SignUpManager from'./Containers/LandingPage/SignUpManager';
import ChangePasswordManager from './Containers/LandingPage/ChangePasswordManager';
import ResetPasswordManager from './Containers/LandingPage/ResetPasswordManager';
import OtpVerification from'./Containers/LandingPage/OtpManager';
import ProvacyPolicyManager from'./Containers/LandingPage/ProvacyPolicyManager';
import TermConditionsManager from'./Containers/LandingPage/TermConditionsManager';
import RechargeManager from'./Containers/LandingPage/Recharge&InquieyManager';
import SohojSupportManager from './Containers/LandingPage/SohojSupportManager';
import NoRouteFoundManager from './Containers/Home/NoRouteFoundManager';
import BotReportManager from './Containers/BotReport/BotReportManager'

function Loading() {
    return <RingLoader
        className={override}
        sizeUnit={"px"}
        size={150}
        color={'#E23714'}
        loading= {true}
    />;
}

const DashBoardManager = Loadable({
    loader: () => import('./Containers/Home/DashBoardManager'),
      delay:60,loading: Loading
});

const FundTransferManager = Loadable({
    loader: () => import('./Containers/Home/FundTransferManager'),
      delay:60,loading: Loading
});
const InternetManager = Loadable({
    loader: () => import('./Containers/Home/InternetManager'),
      delay:60,loading: Loading
});
//import DashBoardManager from './Containers/Home/DashBoardManager';
//import FundTransferManager from './Containers/Home/FundTransferManager';
//import InternetManager from './Containers/Home/InternetManager';

const ReportManager = Loadable({
    loader: () => import('./Containers/Home/Report/ReportManager'),
      delay:60,loading: Loading
});
const DepositReportManager = Loadable({
    loader: () => import('./Containers/Home/Report/DepositReportManager'),
      delay:60,loading: Loading
});
const ExecutionReportManager = Loadable({
    loader: () => import('./Containers/Home/Report/ExecutionReportManager'),
      delay:60,loading: Loading
});
const SalesReportManager = Loadable({
    loader: () => import('./Containers/Home/Report/SalesReportManager'),
      delay:60,loading: Loading
});
/*const DevReportManager = Loadable({
    loader: () => import('./Containers/Home/Report/DevReportManager'),
      delay:60,loading: Loading
});*/


//import ReportManager from './Containers/Home/Report/ReportManager';
//import DepositReportManager from './Containers/Home/Report/DepositReportManager';
//import ExecutionReportManager from './Containers/Home/Report/ExecutionReportManager';
//import SalesReportManager from './Containers/Home/Report/SalesReportManager';
//import DevReportManager from './Containers/Home/Report/DevReportManager';


const AdminDashboard = Loadable({
    loader: () => import('./Containers/Home/AdminDashboardManager'),
      delay:60,loading: Loading
});
const RoleManager = Loadable({
    loader: () => import('./Containers/Home/Role/RoleManager'),
      delay:60,loading: Loading
});
const RoleSettingsManager = Loadable({
    loader: () => import('./Containers/Home/Role/RoleSettingsManager'),
      delay:60,loading: Loading
});
const TopUpManager = Loadable({
    loader: () => import('./Containers/Home/TopUpManager'),
      delay:60,loading: Loading
});
const BulkUploadManager = Loadable({
    loader: () => import('./Containers/Home/BulkUploadManager'),
      delay:60,loading: Loading
});

const CashRechargeManager = Loadable({
    loader: () => import('./Containers/Home/CashRechargeManager'),
    delay:60,loading: Loading
});

//import AdminDashboard from './Containers/Home/AdminDashboardManager';
//import RoleManager from './Containers/Home/Role/RoleManager';
//import RoleSettingsManager from './Containers/Home/Role/RoleSettingsManager';
//import TopUpManager from './Containers/Home/TopUpManager';
//import BulkUploadManager from './Containers/Home/BulkUploadManager';


const UserManagement = Loadable({
    loader: () => import('./Containers/Home/User/UserManagement'),
      delay:60,loading: Loading
});
const UserCreateManager = Loadable({
    loader: () => import('./Containers/Home/User/UserCreateManager'),
      delay:60,loading: Loading
});
const UserCommissionManagement = Loadable({
    loader: () => import('./Containers/Home/User/UserCommissionManagement'),
      delay:60,loading: Loading
});
const UserCurrency = Loadable({
    loader: () => import('./Containers/Home/User/UserCurrencyManager'),
      delay:60,loading: Loading
});
const UserConfiguration = Loadable({
    loader: () => import('./Containers/Home/User/UserConfigurationManager'),
      delay:60,loading: Loading
});

//import UserManagement from './Containers/Home/User/UserManagement';
//import UserCreateManager from './Containers/Home/User/UserCreateManager';
//import UserCommissionManagement from './Containers/Home/User/UserCommissionManagement';
//import UserCurrency from './Containers/Home/User/UserCurrencyManager';
//import UserConfiguration from './Containers/Home/User/UserConfigurationManager';

const SohojRechargeManagement = Loadable({
    loader: () => import('./Containers/Home/SohojRechargeManagement'),
      delay:60,loading: Loading
});
const PinManagement = Loadable({
    loader: () => import('./Containers/Home/PinManagement'),
    delay:60,loading: Loading
});

const CashRechargeAgent = Loadable({
    loader: () => import('./Containers/Home/User/CashRechargeAgentManager'),
    delay:60,loading: Loading
});


//import SohojRechargeManagement from './Containers/Home/SohojRechargeManagement';

const CardGenerateManager = Loadable({
    loader: () => import('./Containers/Home/Lot_Card/CardGenerateManager'),
      delay:60,loading: Loading
});
const LotGenerateManager = Loadable({
    loader: () => import('./Containers/Home/Lot_Card/LotGenerateManager'),
      delay:60,loading: Loading
});
const LotActivationManager = Loadable({
    loader: () => import('./Containers/Home/Lot_Card/LotActivationManager'),
      delay:60,loading: Loading
});
const LotProductRelationManager = Loadable({
    loader: () => import('./Containers/Home/Lot_Card/LotProductRelationManager'),
      delay:60,loading: Loading
});


//import CardGenerateManager from './Containers/Home/Lot_Card/CardGenerateManager';
//import LotGenerateManager from './Containers/Home/Lot_Card/LotGenerateManager';
//import LotActivationManager from './Containers/Home/Lot_Card/LotActivationManager';
///import LotProductRelationManager from './Containers/Home/Lot_Card/LotProductRelationManager';

const salesCardInvoice = Loadable({
    loader: () => import('./Containers/Home/Sales/SalesCardInvoiceManager'),
      delay:60,loading: Loading
});
const salesDeposits = Loadable({
    loader: () => import('./Containers/Home/Sales/SalesDepositsManager'),
      delay:60,loading: Loading
});
const salesFundTransferRequests = Loadable({
    loader: () => import('./Containers/Home/Sales/SalesFundTransferRequestsManager'),
      delay:60,loading: Loading
});
const SalesRouteManager = Loadable({
    loader: () => import('./Containers/Home/Sales/SalesRouteManager'),
      delay:60,loading: Loading
});
const CardTransferManager = Loadable({
    loader: () => import('./Containers/Home/Sales/CardTransferManager'),
      delay:60,loading: Loading
});
const CardReportManager = Loadable({
    loader: () => import('./Containers/Home/Sales/CardReportManager'),
      delay:60,loading: Loading
});


//import salesCardInvoice from './Containers/Home/Sales/SalesCardInvoiceManager';
//import salesDeposits from './Containers/Home/Sales/SalesDepositsManager';
//import salesFundTransferRequests from './Containers/Home/Sales/SalesFundTransferRequestsManager';
//import SalesRouteManager from './Containers/Home/Sales/SalesRouteManager';
//import CardTransferManager from './Containers/Home/Sales/CardTransferManager';
//import CardReportManager from './Containers/Home/Sales/CardReportManager';


const SettingsNotificationHandler = Loadable({
    loader: () => import('./Containers/Home/Settings/NotificationHandler'),
      delay:60,loading: Loading
});
const SettingsChangePasswordManager = Loadable({
    loader: () => import('./Containers/Home/Settings/ChangePasswordManager'),
      delay:60,loading: Loading
});
const SettingsProfileUpdateManager = Loadable({
    loader: () => import('./Containers/Home/Settings/SettingsProfileUpdateManager'),
      delay:60,loading: Loading
});
const settingsPurchaseBalanceManager = Loadable({
    loader: () => import('./Containers/Home/Settings/SettingsPurchaseBalanceManager'),
      delay:60,loading: Loading
});

//import SettingsNotificationHandler from './Containers/Home/Settings/NotificationHandler';
//import SettingsChangePasswordManager from './Containers/Home/Settings/ChangePasswordManager';
//import SettingsProfileUpdateManager from './Containers/Home/Settings/SettingsProfileUpdateManager';
//import settingsPurchaseBalanceManager from './Containers/Home/Settings/SettingsPurchaseBalanceManager';

const ResellerManagement = Loadable({
    loader: () => import('./Containers/Home/Reseller/ResellerManagement'),
      delay:60,loading: Loading
});
const ResellerCreateManager = Loadable({
    loader: () => import('./Containers/Home/Reseller/ResellerCreateManager'),
      delay:60,loading: Loading
});

//import ResellerManagement from './Containers/Home/Reseller/ResellerManagement';
//import ResellerCreateManager from './Containers/Home/Reseller/ResellerCreateManager';

const RechargeInquiry = Loadable({
    loader: () => import('./Containers/Home/Recharge&Inquiry'),
      delay:60,loading: Loading
});

//import RechargeInquiry from './Containers/Home/Recharge&Inquiry';
const AddBalanceManager = Loadable({
    loader: () => import('./Containers/Home/AdminSettings/AddBalanceManager'),
      delay:60,loading: Loading
});

//import AddBalanceManager from './Containers/Home/AdminSettings/AddBalanceManager';
const ProductListManager = Loadable({
    loader: () => import('./Containers/Home/Product/ProductListManager'),
      delay:60,loading: Loading
});
const ProductRateManager = Loadable({
    loader: () => import('./Containers/Home/Product/ProductRateManager'),
      delay:60,loading: Loading
});

const ProductCreateManager = Loadable({
    loader: () => import('./Containers/Home/Product/ProductCreateManager'),
      delay:60,loading: Loading
});

//import ProductListManager from './Containers/Home/Product/ProductListManager';
//import ProductRateManager from './Containers/Home/Product/ProductRateManager';
//import ProductCreateManager from './Containers/Home/Product/ProductCreateManager';

const PackageManager = Loadable({
    loader: () => import('./Containers/Home/Package/PackageManager'),
      delay:60,loading: Loading
});
const SupportManager = Loadable({
    loader: () => import('./Containers/Home/SupportManager'),
      delay:60,loading: Loading
});

//import PackageManager from './Containers/Home/Package/PackageManager';





/*import test from './Containers/test';*/

const override = css`
    display: block;
    margin: 300px auto;
    border-color: red;
    z-index: 1`;



class App extends Component {

        constructor(props){
            super(props);
            this.state = {
                render : false
            }
        }

        componentDidMount(){
            setTimeout(this.getToken(),500);
        }

    getDaysBetweenDates =(d0, d1)=> {

         let msPerDay = 8.64e7;
         let x0 = new Date(d0);
         let x1 = new Date(d1);
        x0.setHours(12,0,0);
        x1.setHours(12,0,0);
        return Math.round( (x1 - x0) / msPerDay );
    };
        getData=(d)=>{
            return d.getFullYear()+'/'+d.getMonth()+'/'+d.getDate()+' '+ d.getHours()+':'+d.getMinutes();
        };
        getToken(){
            axiosAuth.post(apiRoute.token,{
                "clientId": "sohoj-app",
                "clientSecret": "wOA%Z%'C)>fs1},",
                "grantType": "client_credentials",
                "nonce": Math.floor(Math.random() * 10000).toString()
            }).then(res =>{
                if(res.status === 200){
                    if(res.data.status === 200){
                        let prevDate = localStorage.getItem('ss_t');
                        let curDate = this.getData(new Date());
                        localStorage.setItem('authToken', res.data.data[0].access_token);
                        localStorage.setItem('token_type', res.data.data[0].token_type);

                        if(this.getDaysBetweenDates(prevDate, curDate) > 0){
                            localStorage.removeItem('activeSubMenu');
                            localStorage.removeItem('activeMenu');
                            localStorage.removeItem('sessionData');
                            localStorage.removeItem('role');
                            localStorage.removeItem('currency');
                            localStorage.removeItem('activeCurrency');
                            localStorage.removeItem('notification');
                            localStorage.setItem('ss_t', curDate);
                        }else{
                            localStorage.setItem('ss_t', curDate);
                        }




                        this.setState({'render': true});
                    }
                }
            })
        }

        render() {

            if(this.state.render) {

                return (

                    <Switch>
                        <Route path="/privacy-policies" exact component={ProvacyPolicyManager}/>
                        <Route path="/term-conditions" exact component={TermConditionsManager}/>
                        <PrivateRouteNotAuth path="/otp" exact component={OtpVerification}/>
                        <PrivateRouteNotAuth path="/forget-password" exact component={ForgetPasswordManager}/>
                        <PrivateRouteNotAuth path="/signin" exact component={SignInManager}/>
                        <PrivateRouteNotAuth path="/signup" exact component={SignUpManager}/>
                        <PrivateRouteNotAuth path="/change-password" exact component={ChangePasswordManager}/>
                        <PrivateRouteNotAuth path="/reset-password" exact component={ResetPasswordManager}/>
                        <PrivateRouteNotAuth path="/Message" exact component={MessageManager}/>
                        <PrivateRouteNotAuth path="/supports" exact component={SohojSupportManager}/>
                        <PrivateRouteNotAuth path="/" exact component={RechargeManager}/>

                        <PrivateRouteAuth path="/dashboard" exact component={DashBoardManager}/>
                        <PrivateRouteAuth path="/topup" exact component={TopUpManager}/>
                        <PrivateRouteAuth path="/fund-transfer" exact component={FundTransferManager}/>
                        <PrivateRouteAuth path="/internet" exact component={InternetManager}/>
                        <PrivateRouteAuth path="/bulk-flexiload" exact component={BulkUploadManager}/>
                        <PrivateRouteAuth path="/cash-recharge" exact component={CashRechargeManager}/>

                        <PrivateRouteAuth path="/report" exact component={ReportManager}/>
                        <PrivateRouteAuth path="/sales-report" exact component={SalesReportManager}/>
                        <PrivateRouteAuth path="/deposit-report" exact component={DepositReportManager}/>
                        <PrivateRouteAuth path="/execution-report" exact component={ExecutionReportManager}/>
                        {/*<PrivateRouteAuth path="/dev-report" exact component={DevReportManager}/>*/}


                        <PrivateRouteAuth path="/admin-dashboard" exact component={AdminDashboard}/>
                        <PrivateRouteAuth path="/role" exact component={RoleManager}/>
                        <PrivateRouteAuth path="/role-settings" exact component={RoleSettingsManager}/>

                        <PrivateRouteAuth path="/users-view" exact component={UserManagement}/>
                        <PrivateRouteAuth path="/users-create" exact component={UserCreateManager}/>
                        <PrivateRouteAuth path="/user-commission" exact component={UserCommissionManagement}/>
                        <PrivateRouteAuth path="/user-currency" exact component={UserCurrency}/>
                        <PrivateRouteAuth path="/system-configuration" exact component={UserConfiguration}/>
                        <PrivateRouteAuth path="/sohoj-recharge" exact component={SohojRechargeManagement}/>
                        <PrivateRouteAuth path="/cash-agent" exact component={CashRechargeAgent}/>

                        <PrivateRouteAuth path="/pin-generate" exact component={PinManagement}/>

                        <PrivateRouteAuth path="/card-generate" exact component={CardGenerateManager}/>
                        <PrivateRouteAuth path="/lot-generate" exact component={LotGenerateManager}/>
                        <PrivateRouteAuth path="/product-activation" exact component={LotActivationManager}/>
                        <PrivateRouteAuth path="/lot-product-relation" exact component={LotProductRelationManager}/>

                        <PrivateRouteAuth path="/sales-card-invoice" exact component={salesCardInvoice}/>
                        <PrivateRouteAuth path="/sales-deposits" exact component={salesDeposits}/>
                        <PrivateRouteAuth path="/purchase-request" exact component={salesFundTransferRequests}/>
                        <PrivateRouteAuth path="/sales-route" exact component={SalesRouteManager}/>
                        <PrivateRouteAuth path="/card-transfer" exact component={CardTransferManager}/>
                        <PrivateRouteAuth path="/card-report" exact component={CardReportManager}/>

                        <PrivateRouteAuth path="/settings-change-password" exact component={SettingsChangePasswordManager}/>
                        <PrivateRouteAuth path="/settings-notification" exact component={SettingsNotificationHandler}/>
                        <PrivateRouteAuth path="/settings-profile-update" exact component={SettingsProfileUpdateManager}/>
                        <PrivateRouteAuth path="/purchase-balance" exact component={settingsPurchaseBalanceManager}/>

                        <PrivateRouteAuth path="/reseller-view" exact component={ResellerManagement}/>
                        <PrivateRouteAuth path="/reseller-create" exact component={ResellerCreateManager}/>

                        <PrivateRouteAuth path="/add-balance" exact component={AddBalanceManager}/>
                        <PrivateRouteAuth path="/recharge-inquiry" exact component={RechargeInquiry}/>

                        <PrivateRouteAuth path="/product" exact component={ProductListManager}/>
                        <PrivateRouteAuth path="/product-rate" exact component={ProductRateManager}/>
                        <PrivateRouteAuth path="/product-create" exact component={ProductCreateManager}/>


                        <PrivateRouteAuth path="/package" exact component={PackageManager}/>
                        <PrivateRouteAuth path="/support" exact component={SupportManager}/>

                        {/*   <PrivateRouteAuth path="/test" exact component={test}/>*/}

                        <Route path="/sign-out" exact component={SignOutManager}/>
                        <Route path="/not-found" exact component={NoRouteFoundManager} />


                        <Route path="/fb-bot-report" exact component={BotReportManager}/>

                        {/*<Route path="*" exact component={NoRouteFoundManager} />*/}
                        <PrivateRouteAuth path="*" exact component={DashBoardManager} />
                    </Switch>

                );
            }else{
                return (
                    <RingLoader
                        className={override}
                        sizeUnit={"px"}
                        size={150}
                        color={'#E23714'}
                        loading={this.state.loading}
                    />
                )
            }
  }
}

export default withRouter(App);
