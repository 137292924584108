import React , {Component} from 'react';
import {  Link } from 'react-router-dom';
import Auxi from '../../hoc/Auxi';
class ResetPassword extends Component{
    render(){
        let btnDisable = '';
        (this.props.errorMessage.oldPasswordError || this.props.errorMessage.newPasswordError || this.props.errorMessage.retypePasswordError) ? btnDisable = "disabled" : btnDisable = "";
        return(
            <Auxi>
                <div className="card">
                    <div className="card-body login-card-body">
                        <form action="" method="post" autoComplete="off">
                            <div className="row">
                                <div className="col-lg-12 col-12 max-auto">
                                    <div className={"form-group "+ this.props.errorMessage.oldPasswordError ? this.props.errorMessage.oldPasswordError : '' +"has-feedback"}>
                                        <label>Old Password</label>
                                        <input onChange={ (e) =>{this.props.stateHandler(e, 'oldPassword')}}
                                               type="password" className="form-control"
                                               placeholder="Old Password"/>
                                        { <span className="help-block">
                                                            <strong> {this.props.errorMessage.oldPasswordErrorMessage }</strong>
                                                        </span> }
                                    </div>
                                </div>
                                <div className="col-lg-12 col-12 max-auto">
                                    <div className={"form-group "+ this.props.errorMessage.newPasswordError ? this.props.errorMessage.newPasswordError : '' +"has-feedback"}>
                                        <label>New Password</label>
                                        <input onChange={ (e) =>{this.props.stateHandler(e, 'newPassword')}}
                                               type="password" className="form-control"
                                               placeholder="New Password"/>
                                        {<span className="help-block">
                                                            <strong> {this.props.errorMessage.newPasswordErrorMessage }</strong>
                                                        </span> }
                                    </div>
                                </div>
                                <div className="col-lg-12 col-12 max-auto">
                                    <div className={"form-group "+ this.props.errorMessage.retypePasswordError ? this.props.errorMessage.retypePasswordError : '' +"has-feedback"}>
                                        <label>Re-Type Password</label>
                                        <input onChange={ (e) =>{this.props.stateHandler(e, 'retypePassword')}}
                                               type="password" className="form-control"
                                               placeholder="Re-Type Password"/>
                                        {<span className="help-block">
                                                            <strong> {this.props.errorMessage.retypePasswordErrorMessage }</strong>
                                                        </span> }
                                    </div>
                                </div>
                            </div>
                            <div className="row" style={{paddingTop: '20px'}}>

                                <div className="col-lg-4 col-6">
                                    <button disabled={btnDisable} onClick={(e)=> { if (window.confirm('Are you sure you wish to reset?')) this.props.updateSelectedUser(e, 'password', '')}} type="button" className="btn btn-md btn-success btn-md">Save</button>
                                </div>
                                <div className="col-lg-4 col-6">
                                    <p className="mb-1">
                                        <Link to='/signin' className="text-center">Sign In</Link>
                                    </p>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </Auxi>
        );
    }
}


export default ResetPassword;