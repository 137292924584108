import React from 'react';
import Auxi from '../../hoc/Auxi';

const TermConditionsManager = ()=> {

    return (
        <Auxi>
            <p>

                Welcome to the SOHOJ Terms of Service (the "Terms"). Please read these Terms and our Privacy Policy (www.Sohoj.io/privacy) carefully because they govern your use of our website located at https://Sohoj.io (the "Site") and the services we offer through our products and our mobile device application ("App"). To make these Terms easier to read, the Site, our services and App are collectively called the "Services."
            <br/>
            <br/>
                    <b>Agreement to Terms</b><br/>
                    By using our Services, you agree to be bound by these Terms. If you donâ€™t agree to be bound by these Terms, do not use the Services. If you are accessing and using the Services on behalf of a company (such as your employer) or other legal entity, you represent and warrant that you have the authority to bind that company or other legal entity and each of its affiliates to these Terms. In that case, "you" and "your" will refer to that company or other legal entity and its affiliates.

                <br/><br/>
                        <b>Privacy Policy</b><br/>
                        Please refer to our Privacy Policy (www.Sohoj.my/privacy) for information on how we collect, use and disclose information from our users. You acknowledge and agree that your use of the Services is subject to our Privacy Policy.

                <br/><br/>
                            <b>Changes to Terms or Services</b><br/>
                            We may modify the Terms at any time, in our sole discretion. If we do so, weâ€™ll let you know either by posting the modified Terms on the Site or through other communications. Itâ€™s important that you review the Terms whenever we modify them because if you continue to use the Services after we have posted modified Terms on the Site, you are indicating to us that you agree to be bound by the modified Terms. If you donâ€™t agree to be bound by the modified Terms, then you may not use the Services anymore. Because our Services are evolving over time we may change or discontinue all or any part of the Services, at any time and without notice, at our sole discretion.

                <br/>
                                <b>Who May Use the Services</b><br/>

                                Eligibility
                                You may use the Services only if you are 18 years or older and capable of forming a binding contract with SOHOJ and are not barred from using the Services under applicable law.
                                Registration and Your Information
                                If you want to use certain features of the Services youâ€™ll have to create an account ("Account"). You can do this via the SOHOJ site or app.
                                Itâ€™s important that you provide us with accurate, complete and up-to-date information for your Account and you agree to update such information, as needed, to keep it accurate, complete and up-to-date. If you donâ€™t, we might have to suspend or terminate your Account. You agree that you wonâ€™t disclose your Account password to anyone and youâ€™ll notify us immediately of any unauthorized use of your Account. Youâ€™re responsible for all activities that occur under your Account, whether or not you know about them.

                                <br/><br/>
                                    <b>Feedback</b><br/>
                                    We welcome feedback, comments, and suggestions for improvements to the Services ("Feedback"). You can submit Feedback by emailing us at support@Sohoj.io. You grant to us a non-exclusive, worldwide, perpetual, irrevocable, fully-paid, royalty-free, sublicensable and transferable license under any and all intellectual property rights that you own or control to use, copy, modify, create derivative works based upon and otherwise exploit the Feedback for any purpose.

                                    <br/><br/>
                                        <b>Content and Content Rights</b><br/>
                                        For purposes of these Terms "Content" means text, graphics, images, music, software, audio, video, works of authorship of any kind, and information or other materials that are posted, generated, provided or otherwise made available through the Services.

                                        <br/><br/>
                                            <b>Content Ownership, Responsibility, and Removal</b><br/>

                                            SOHOJ and its licensors exclusively own all right, title and interest in and to the Services and Content, including all associated intellectual property rights. You acknowledge that the Services and Content are protected by copyright, trademark, and other laws of the United States and foreign countries. You agree not to remove, alter or obscure any copyright, trademark, service mark or other proprietary rights notices incorporated in or accompanying the Services or Content.
                                            Rights in Content Granted by SOHOJ
                                            Subject to your compliance with these Terms, SOHOJ grants you a limited, non-exclusive, non-transferable, non-sublicensable license to download, view, copy, display and print the Content solely in connection with your permitted use of the Services and solely for your personal and non-commercial purposes.

                                            <br/><br/>
                                                <b>Rights and Terms for Software and Apps</b><br/>
                                                Rights in Software
                                                Subject to your compliance with these Terms, with respect to any software installed or embedded in any SOHOJ products ("Software"), SOHOJ grants you a limited non-exclusive, non-transferable, non-sublicense able license to use the Software on the SOHOJ product solely for your own personal non-commercial purposes. You may not: (i) copy, modify, translate or create derivative works based on the Software; (ii) distribute, transfer, publish, disclose, sublicense, lease, lend, sell or rent the Software to any third party; (iii) reverse engineer, decompile, reverse decompile or disassemble the Software, or otherwise attempt to derive the source code; (iv) make the functionality of the Software available to third parties or multiple users through any means, or (v) benchmark or conduct any performance or comparison tests on the Software. SOHOJ reserves all rights in and to the Software not expressly granted to you under these Terms.
                                                Rights in App Granted by SOHOJ
                                                Subject to your compliance with these Terms, SOHOJ grants you a limited non-exclusive, non-transferable, non-sublicense able license to download and install a copy of the App on a mobile device or computer that you own or control and to run such copy of the App solely for your own personal non-commercial purposes. You may not copy the App, except for making a reasonable number of copies for backup or archival purposes. Except as expressly permitted in these Terms, you may not: (i) copy, modify, translate or create derivative works based on the App; (ii) distribute, transfer, publish, disclose, sublicense, lease, lend, sell or rent the App to any third party; (iii) reverse engineer, decompile, reverse decompile or disassemble the App, or otherwise attempt to derive the source code; (iv) make the functionality of the App available to third parties or multiple users through any means, or (v) benchmark or conduct any performance or comparison tests on the App. SOHOJ reserves all rights in and to the App not expressly granted to you under these Terms.
                                                Rights in Subscription Service
                                                Subject to your payment for the applicable term of the subscription (e.g. 12 months), SOHOJ will provide you with its cloud connectivity that fights internet of things hacking and protects from identity theft, financial fraud, device destruction and other home security problems by automatically blocking hacks, based on machine learning, behavioral intelligence and cloud data (the "Subscription Service") for your own personal non-commercial purposes. The term of the Subscription Service will continue from the date of purchase of the applicable SOHOJ product (or the date you purchase a renewed subscription) and continue for the applicable term purchased. SOHOJ will have the right to terminate the Subscription Service prior to expiration of the applicable term by providing you notice, provided that if you purchased a term by paying in advance (e.g. a $0 Subscription or 12-month Subscription), then upon any termination SOHOJ will refund to you a pro-rata portion of the payment you made for the term of the Subscription Services (the $0 Subscription will be deemed a 30 months term for refund purpose). By way of example, if you paid $60 for a 12- month term of the Subscription Services, and SOHOJ terminates the term after 6 months, then SOHOJ would refund $30.


                                                <br/><br/>
                                                    <b>General Prohibitions and SOHOJ Enforcement Rights</b><br/>
                                                    You agree not to do any of the following:
                                                    â€¢	Use, display, mirror or frame the Services or any individual element within the Services, SOHOJâ€™s name, any SOHOJ trademark, logo or other proprietary information, or the layout and design of any page or form contained on a page, without SOHOJâ€™s express written consent;
                                                    â€¢	Access, tamper with, or use non-public areas of the Services, SOHOJâ€™s computer systems, or the technical delivery systems of SOHOJâ€™s providers;
                                                    â€¢	Attempt to probe, scan or test the vulnerability of any SOHOJ system or network or breach any security or authentication measures;
                                                    â€¢	Avoid, bypass, remove, deactivate, impair, descramble or otherwise circumvent any technological measure implemented by SOHOJ or any of SOHOJâ€™s providers or any other third party (including another user) to protect the Services or Content;
                                                    â€¢	Attempt to access or search the Services or Content or download Content from the Services through the use of any engine, software, tool, agent, device or mechanism (including spiders, robots, crawlers, data mining tools or the like) other than the software and/or search agents provided by SOHOJ or other generally available third-party web browsers;
                                                    â€¢	Send any unsolicited or unauthorized advertising, promotional materials, email, junk mail, spam, chain letters or other forms of solicitation;
                                                    â€¢	Use any meta tags or other hidden text or metadata utilizing an SOHOJ trademark, logo URL or product name without SOHOJâ€™s express written consent;
                                                    â€¢	Use the Services or Content, or any portion thereof, for any commercial purpose or for the benefit of any third party or in any manner not permitted by these Terms;
                                                    â€¢	Forge any TCP/IP packet header or any part of the header information in any email or newsgroup posting, or in any way use the Services or Content to send altered, deceptive or false source-identifying information;
                                                    â€¢	Attempt to decipher, decompile, disassemble or reverse engineer any of the software used to provide the Services or Content;
                                                    â€¢	Interfere with, or attempt to interfere with, the access of any user, host or network, including, without limitation, sending a virus, overloading, flooding, spamming, or mail-bombing the Services;
                                                    â€¢	Collect or store any personally identifiable information from the Services from other users of the Services without their express permission;
                                                    â€¢	Impersonate or misrepresent your affiliation with any person or entity;
                                                    â€¢	Violate any applicable law or regulation; or
                                                    â€¢	Encourage or enable any other individual to do any of the foregoing.
                                                    Although weâ€™re not obligated to monitor access to or use of the Services or Content or to review or edit any Content, we have the right to do so for the purpose of operating the Services, to ensure compliance with these Terms, and to comply with applicable law or other legal requirements. We reserve the right but are not obligated, to remove or disable access to any Content, at any time and without notice, including, but not limited to, if we, at our sole discretion, consider any Content to be objectionable or in violation of these Terms. We have the right to investigate violations of these Terms or conduct that affects the Services. We may also consult and cooperate with law enforcement authorities to prosecute users who violate the law.

                                                    <br/><br/>
                                                        <b>Links to Third Party Websites or Resources</b><br/>
                                                        The Services and App may contain links to third-party websites or resources. We provide these links only as a convenience and are not responsible for the content, products or services on or available from those websites or resources or links displayed on such websites. You acknowledge sole responsibility for and assume all risk arising from, your use of any third-party websites or resources.

                                                        <br/><br/>
                                                            <b>Termination</b><br/>
                                                            We may terminate your access to and use of the Services, at our sole discretion, at any time and without notice to you. You may cancel your Account at any time by sending an email to us at support@Sohoj.io. Upon any termination, discontinuation or cancellation of Services or your Account, all provisions of these Terms which by their nature should survive will survive, including, without limitation, ownership provisions, warranty disclaimers, limitations of liability, and dispute resolution provisions.


                                                            <br/><br/>
                                                                <b>Warranty Disclaimers</b><br/>

                                                                SOHOJ provides a limited warranty on its hardware device as follows: For a period of one (1) year following the purchase date of the device, SOHOJ will repair or replace any device that was defective as of the date of purchase. SOHOJ does not provide any warranty for any data that may be stored on the device or SOHOJâ€™s servers and SOHOJ does not provide any warranty regarding such data. EXCEPT FOR THE FOREGOING WARRANTY, THE SERVICES AND CONTENT ARE PROVIDED "AS IS," WITHOUT WARRANTY OF ANY KIND. WITHOUT LIMITING THE FOREGOING, WE EXPLICITLY DISCLAIM ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, QUIET ENJOYMENT OR NON-INFRINGEMENT, AND ANY WARRANTIES ARISING OUT OF COURSE OF DEALING OR USAGE OF TRADE. We make no warranty that the Services will meet your requirements or be available on an uninterrupted, secure, or error-free basis. We make no warranty regarding the quality, accuracy, timeliness, truthfulness, completeness or reliability of any Content.

                                                                <br/><br/>
                                                                    <b>Indemnity</b><br/>
                                                                    You will indemnify and hold harmless SOHOJ and its officers, directors, employee and agents, from and against any claims, disputes, demands, liabilities, damages, losses, and costs and expenses, including, without limitation, reasonable legal and accounting fees arising out of or in any way connected with, (ii) your use of these Services, or (iii) your breach of these Terms.

                                                                    <br/><br/>
                                                                        <b>Limitation of Liability</b><br/>
                                                                        Neither SOHOJ nor any other party involved in creating, producing, or delivering the services or content will be liable for any incidental, special, exemplary or consequential damages, including lost profits, loss of data or goodwill, service interruption, computer damage or network interruption or impact on other devices or system failure or the cost of substitute services arising out of or in connection with these terms or from the use of or inability to use the services or content, whether based on warranty, contract, product liability or any other legal theory, and whether or not SOHOJ has been informed of the possibility of such damage, even if a limited remedy set forth herein is found to have failed of its essential purpose. Some jurisdictions do not allow the exclusion or limitation of liability for consequential or incidental damages, so the above limitation may not apply to you.
                                                                        In no event will SOHOJâ€™S total liability arising out of or in connection with these terms or from the use of or inability to use the services or content exceed the amounts you have paid to SOHOJ for use of the services or content in the six (6) months prior to the applicable event.
                                                                        The exclusions and limitations of damages set forth above are fundamental elements of the basis of the bargain between SOHOJ and you.

                                                                        <br/><br/>
                                                                            <b>General Terms</b><br/>
                                                                            These Terms constitute the entire and exclusive understanding and agreement between SOHOJ and you regarding the Services and Content, and these Terms supersede and replace any and all prior oral or written understandings or agreements between SOHOJ and you regarding the Services and Content.
                                                                            Any notices or other communications provided by SOHOJ under these Terms, including those regarding modifications to these Terms, will be given: (i) via email; or (ii) by posting to the Services. For notices made by e-mail, the date of receipt will be deemed the date on which such notice is transmitted.
                                                                            SOHOJâ€™s failure to enforce any right or provision of these Terms will not be considered a waiver of such right or provision. The waiver of any such right or provision will be effective only if in writing and signed by a duly authorized representative of SOHOJ. Except as expressly set forth in these Terms, the exercise by either party of any of its remedies under these Terms will be without prejudice to its other remedies under these Terms or otherwise.


            </p>
        </Auxi>
    )
};

export default TermConditionsManager;