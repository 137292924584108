//   imageUrl(https) : 'https://sohojdev.dotlines.com.sg/',
//   imageUrl(http) : 'http://45.249.100.18:3001/',

let server = process.env.REACT_APP_DEVELOPMENT_SERVER;
console.log("server is", server);

const apiRoute = {
    // baseUrl : 'https://api.sohoj.my/api/',
    // baseUrlBot : 'https://api.sohoj.my/bot/',
    // baseUrlAuth: 'https://api.sohoj.my/auth/',
    // imageUrl : 'https://api.sohoj.my/',
    // fileUrl: 'https://api.sohoj.my',
    

    // baseUrl : 'http://45.249.100.23:3001/api/',
    // baseUrlBot : 'http://45.249.100.23:3001/bot/',
    // baseUrlAuth: 'http://45.249.100.23:3001/auth/',
    // imageUrl : 'http://45.249.100.23:3001/',
    // fileUrl: 'http://45.249.100.23:3001',

    baseUrl : server === 'development' ? 'http://45.249.100.23:3001/api/' : 'https://api.sohoj.my/api/',
    baseUrlBot : server === 'development' ? 'http://45.249.100.23:3001/bot/' : 'https://api.sohoj.my/bot/',
    baseUrlAuth: server === 'development' ? 'http://45.249.100.23:3001/auth/' : 'https://api.sohoj.my/auth/',
    imageUrl : server === 'development' ? 'http://45.249.100.23:3001/' : 'https://api.sohoj.my/',
    fileUrl: server === 'development' ? 'http://45.249.100.23:3001' : 'https://api.sohoj.my',

    token : 'token',
    accountName: 'test',
    accountPassword: 'test',
	contactUserList: 'contact-user-list',
    signIn: 'admin/login',
    signUp: 'sign-up',
    otp: 'verify-otp',
    otpPurpose: {
        login: 'login',
        fund: 'fund-transfer',
        internet: 'recharge-request',
        topUp: 'recharge-request',
        recharge: 'sohoj-dialer',
        addBalance: 'add-balance',
        softPin : 'soft-pin',
        sohojCash : 'sohoj-cash'
    },
    currency: {
        currencyList: 'currency-list',
        create: 'currency/create',
        update: 'currency/update',
        show: 'currency/show'
    },
    configuration: {
        create: 'configuration/create',
        update: 'configuration/update',
        show: 'configuration/show'
    },
    signOut: 'logout',
    forgetPassword: '',
    changePassword: 'change-password',
    resetPassword: {
        create: 'reset-password/create',
        verify: 'reset-password/verify',
        update: 'reset-password/update',
    },
    user: {
        createUserProfile: 'create-user-profile',
        getUserList: 'get-user-list',
        getSpecificUserInfo: 'get-specific-user-info',
        getUserInfo: 'get-user-info',
        userProfileUpdate: 'user-profile-update',
        profileUpdate: 'profile-update'
    },
    dashboard: '',
    role: {
        create: 'role/create',
        update: 'role/update',
        delete: 'role/delete',
        show: 'role/show',
        settingsUpdate: 'role-setting/update',
        settingsShow: 'role-setting/show',

    },
    topUp: '',
    fundTransfer: {
        check: 'check-fund-transfer',
        send: 'fund-transfer',
        create: 'fund-transfer-config/create',
        update: 'fund-transfer-config/update',
        show: 'fund-transfer-config/show'
    },
    product: {
      show:  'product/show',
      check: 'check-recharge-request',
      save: 'recharge-request',
      auth: 'product-auth-info',
      dialerRequest: 'dialer-recharge-request',
      dialerChack: 'check-dialer-recharge-request',
      productCategory: 'product-category/show',
      updateRechargeRequest: 'update-recharge-request',
      create: 'product/create',
      update: 'product/update',
      productRateCreate: 'product-rates/create',
      productRateUpdate: 'product-rates/update',
      productRateShow: 'product-rates/show',
      allProduct: 'product/show/all'
    },
    package: {
        show: 'package/show',
        create: 'package/create',
        update: 'package/update',
    },
    internet: '',
    report: 'reports/generate',
    reportDownload: 'reports/download',
    botReport: 'reports/generate',
    lot: {
        create: 'lot/create',
        update: 'lot/update',
        delete: 'lot/delete',
        show: 'lot/show',
        provision: 'lot/provision',
        details: 'lot/details',
        check : 'lot/check-updatable-cards'
    },
    fileUpload: 'file-upload',
    sales:{
        show: 'sales/show',
        create: 'sales/create',
        approve: 'sales/approve',
        getDiscountList : 'get-discount-list'
    },
    reseller: {
        show : 'get-reseller-list',
        create: 'create-reseller-profile'
    },
    addBalance : 'add-balance',
    card: {
        recharge: 'card/recharge',
        status: 'card/status',
        changeStatus: 'card/change-status',
        cardTransferInitiate: 'card/transfer/initiate',
        cardTransferAct: 'card/transfer/act',
        cardTransferShow: 'card/transfer/show',
        download: 'card/download',
        generatePin: 'card/generate-pin',
        summary: '/card/summary',
        promo: '/card/save-promo-user-info'
    },
    route:{
        show: 'route/show',
        create: 'route/create',
        update: 'route/update',
        delete: 'route/delete'
    },
    depositSlip: 'download/deposit-slips',
    bulkUpload: {
            checkFlexiload: '/bulk-recharge-request/check',
            saveFlexiload: '/bulk-recharge-request/finalize'
        },
    cash: {
        setCashAgentId : 'set-cash-agent-id',
        getCastAgentList : 'get-cash-agent-list',
        cashRechargeCheck: 'cash-recharge/check',
        cashRechargeRequest: 'cash-recharge/request',
        checkCashAgentId: 'check-cash-agent-id',
        commission: '/transaction-commission/update'
    }

};

export default apiRoute;
