import React , {Component} from 'react';
import { Redirect } from 'react-router-dom';
import Auxi from '../../hoc/Auxi';
import LayoutLandingPage from '../../Components/Layout/LayoutLandingPage';
import SignUp from '../../Components/SignUp/SignUp';
import axios from '../../axios';
import apiRoute from '../../apiRoute';
import Modal from '../../Components/UI/Modal/Modal';
import ModalContent from '../../Components/UI/Modal/ModalContent';
import { css } from 'react-emotion';
import { RingLoader } from 'react-spinners';
import getSession from '../../Components/UI/Session/getSession';
import {successMessage, failedMessage} from '../../Message';
const override = css`
    display: block;
    margin: 300px auto;
    border-color: red;
    z-index: 1`;
const error = {
    fullNameError: 'has-error',
    fullNameErrorMessage: 'User Full Name Required',
    usernameError: 'has-error',
    usernameErrorMessage: 'User Name Required',
    passwordError: 'has-error',
    passwordErrorMessage: 'Password Required',
    mobileNoError: 'has-error',
    mobileNoErrorMessage: 'Mobile Number Required',
    emailError: 'has-error',
    emailErrorMessage: 'Email Required',
    captchaError: 'has-error',
    captchaErrorMessage: 'Captcha Required',
    termsError: '',
    termsErrorMessage: ''
};

class SignUpManager extends Component{

    constructor(props){
        super();
        this.numberCaptcha1 = Math.ceil(Math.random()* 10);
        this.numberCaptcha2 = Math.ceil(Math.random()* 10);
        this.numberCaptchaString =  this.numberCaptcha1+' + '+this.numberCaptcha2;
        this.numberCaptchaValue = this.numberCaptcha1+this.numberCaptcha2;
    }

    state = {
        loading: false,
        redirectToReferrer: false,
        signUpsignUptoggleEye : true,
        signUpData : {
            fullName: '',
            username: '',
            password: '',
            countryCode: '',
            mobileNo: '',
            email: '',
            terms: 'on'
        },
        error: {...error},
        userId: '',
        numberCaptchaValue: '',
        numberCaptchaInput: '',
        axiosStatus: {
            status: '',
            title: '',
            details: '',
        },
        modal: false,
    };

    //set captcha in html
    componentDidMount(){
        this.setState({numberCaptchaValue: this.numberCaptchaValue});

    };

    //update captcha through event
    stateCaptchaChangeHandler = (event, index) => {
        let newCaptchca = event.target.value;
        this.setState({numberCaptchaInput: this.numberCaptchaValue},
            () => {this.validateHandler(index, newCaptchca)});
    };

    //state handler except phone & country code
    stateChangeHandler = (event, index) =>{
        if(index === 'terms'){
            const oldState = {...this.state.signUpData};
            let newState = oldState[index] === 'on' ? 'off' : 'on';
            oldState[index] = newState;
            this.setState({signUpData: oldState},
                () => {this.validateHandler(index, newState )});
        }else{
            let newState = event.target.value;
            const oldState = {...this.state.signUpData};
            oldState[index] = newState;
            this.setState({signUpData: oldState},
                () => {this.validateHandler(index, newState )});
        }
    };

    //state handler for country code and phone
    stateCountryCodeHandler = (index, value) =>{
        const oldState = {...this.state.signUpData};
        oldState[index] = value[1];
        oldState['countryCode'] = value[0].replace('+', '');
        this.setState({signUpData: oldState},
            () => {this.validateHandler(index, value[1] )} );
    };

    //validator
    validateHandler= (fieldName, value) => {
        let errorState = this.state.error;

        switch(fieldName){
            case "fullName":

                if(value.length <= 0){
                    errorState.fullNameErrorMessage = 'Full Name Required';
                    errorState.fullNameError = 'has-error';
                }else{
                    errorState.fullNameErrorMessage = '';
                    errorState.fullNameError = '';

                }
                break;
            case "username":

                if(value.length < 6){
                    errorState.usernameErrorMessage = 'User Name Must Have 6 Character';
                    errorState.usernameError = 'has-error';
                }else{
                    errorState.usernameErrorMessage = '';
                    errorState.usernameError = '';

                }
                break;
            case "password":

                if(value.length < 6){
                    errorState.passwordErrorMessage = 'Password Must Have 6 Character';
                    errorState.passwordError = 'has-error';
                }else{
                    errorState.passwordErrorMessage = '';
                    errorState.passwordError = '';
                }
                break;
            case "mobileNo":

                if(value.length < 5){
                    errorState.mobileNoErrorMessage = 'Mobile Number Must Have 5 Character';
                    errorState.mobileNoError = 'has-error';
                }else{
                    errorState.mobileNoErrorMessage = '';
                    errorState.mobileNoError = '';
                }
                break;
            case "email":
                let emailValid = value.match(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/);

                if(!emailValid){
                    errorState.emailErrorMessage = 'Invalid Email Address. Must Have \'@\' and \'.com\'';
                    errorState.emailError = 'has-error';
                }else{
                    errorState.emailErrorMessage = '';
                    errorState.emailError = '';
                }
                break;
            case "numberCaptchaInput":

                if(parseInt(this.numberCaptchaValue,10) !== parseInt(value,10)){

                    errorState.captchaErrorMessage = 'Does Not Match';
                    errorState.captchaError = 'has-error';
                }else{
                    errorState.captchaErrorMessage = '';
                    errorState.captchaError = '';
                }
                break;
            case "terms":

                if(value === 'off'){

                    errorState.termsErrorMessage = 'Please Check';
                    errorState.termsError = 'has-error';
                }else{
                    errorState.termsErrorMessage = '';
                    errorState.termsError = '';
                }
                break;
            default:
                break;
        }

        this.setState({error: errorState})

    };

    //redirect handler after sign up
    signUpRedirectHandler = () => {
        this.setState({redirectToReferrer : true })
    };


    modalHandler = ()=> {
        this.setState({modal: !this.state.modal});
    };

    axiosStatusHandler = (status, title, details)=>{
        let oldState = this.state.axiosStatus;
        oldState.status = status;
        oldState.title = title;
        oldState.details = details;
        this.setState({axiosStatus: oldState});
    };
    spinnerHandler = ()=>{
        this.setState({loading: !this.state.loading})
    };


    //sign up submit
    signupHandler = ()=> {
        let tokenType = getSession('token_type');
        let authToken = getSession('authToken');
        let authorization = { Authorization: ` ${tokenType} ${authToken}` };
        this.spinnerHandler();
        let axiosData = {
            "fullName": this.state.signUpData.fullName,
            "username": this.state.signUpData.username.trim(),
            "password": this.state.signUpData.password,
            "countryCode": this.state.signUpData.countryCode,
            "mobileNo": this.state.signUpData.mobileNo,
            "email": this.state.signUpData.email,
        };
        axios.post(apiRoute.signUp,axiosData, {headers: authorization})
            .then(res => {

                if(res.status === 200){
                    if(res.data.status === 200){
                        this.spinnerHandler();
                        this.axiosStatusHandler(res.data.status, res.data.title, successMessage.signUpSuccess);
                        this.setState({modal: true,userId: res.data.data[0].userId, error: {...error}});
                        this.signUpRedirectHandler();
                    }else{
                        this.spinnerHandler();
                        this.setState({modal: true,error: {...error}});
                        this.axiosStatusHandler(res.data.status, res.data.title, res.data.details);
                    }
                }else{
                    this.spinnerHandler();
                    this.setState({modal: true,error: {...error}});
                    this.axiosStatusHandler(res.data.status, 'Error', failedMessage.httpError);
                }


            });

    };


    //show hide password
    showHidePassword = (event, index) => {
        const doesShowEye = this.state.signUptoggleEye;
        this.setState ( (prevState, props) => {
            return {
                signUptoggleEye: !doesShowEye
            };
        });
    };


    render(){
        let slashClass = '';
        let passwordShow = '';
        if(this.state.signUptoggleEye) {
            slashClass = 'fa fa-fw fa-eye';
            passwordShow = 'text';
        }else{
            slashClass = 'fa fa-fw fa-eye fa-eye-slash';
            passwordShow = 'password'
        }


        if(this.state.loading){
            return(<RingLoader
                className={override}
                sizeUnit={"px"}
                size={150}
                color={'#E23714'}
                loading={this.state.loading}
            />)
        }else if(this.state.redirectToReferrer){
            const message_body = 'Your Account Successfully Created. Please Contact Support';
            const message_data = 'Your Account ID IS '+this.state.userId;
            const path_message = 'I already have a membership! Sign IN Now';
            const path_name = '/signin';

            const { from } =   { from : {pathname: '/Message',
                state:{from: this.props.location.state,
                message_body: message_body, message_data: message_data,userID: this.state.userId,
                path_message: path_message, path_name: path_name}}};

            return <Redirect to={ from }/>
        }else{
            return (
                <Auxi>

                    <Modal show={this.state.modal} modalClosed={this.modalHandler}>
                        <ModalContent
                            status = {this.state.axiosStatus.status}
                            title = {this.state.axiosStatus.title}
                            details = {this.state.axiosStatus.details}
                        />
                    </Modal>

                    <LayoutLandingPage TitleEnd="Sign Up" TitleStart="" boxClass="register-box">
                        <SignUp
                            terms = {this.state.signUpData.terms}
                            errorMessage = {this.state.error}
                            numberCaptcha={this.numberCaptchaString}
                            numberCaptchaValue = {this.numberCaptchaValue}
                            stateCaptchaChangeHandler = {this.stateCaptchaChangeHandler}
                            slashClass={slashClass}
                            showHidePassword={this.showHidePassword}
                            passwordShow={passwordShow}
                            stateChangeHandler = {this.stateChangeHandler}
                            signupHandler = {this.signupHandler}
                            stateCountryCodeHandler = {this.stateCountryCodeHandler}
                        />
                    </LayoutLandingPage>
                </Auxi>
            );

        }
    }
}


export default SignUpManager;
