import getSession from './getSession';
import axios from '../../../axios';
import axiosNotAuth from '../../../axiosNonAuth';
import apiRoute from '../../../apiRoute';

const createSession = (props) => {



    const axiosAccount = {
        "accountName": apiRoute.accountName,
        "accountPassword": apiRoute.accountPassword
    };


    localStorage.setItem('sessionData', JSON.stringify(props));
    // localStorage.setItem('role', (props.menuPermission));


    let hiddenMenu = JSON.parse(props.menuPermission).filter(menu => menu.name == 'Service');
    // console.log("hidden main menu is ", hiddenMenu);
    // console.log("hiddenMenu is ", hiddenMenu[0].sub);
    const deletedMenu = (hiddenMenu[0].sub).filter(subMenu => subMenu.name == 'Fund Transfer');
    // console.log("after hidden menu is ", deletedMenu);
    hiddenMenu[0].sub = [...deletedMenu];
    
    let parsedObject = JSON.parse(props.menuPermission);
    const index = parsedObject.findIndex(menu => menu.name == 'Service');
    parsedObject[index] = {...hiddenMenu[0]};
    // parsedObject.splice(index, 1);

    const profileIndex = parsedObject.findIndex(menu => menu.name == 'Profile');
    parsedObject.splice(profileIndex, 1);
    const resellerIndex = parsedObject.findIndex(menu => menu.name == 'Reseller');
    parsedObject.splice(resellerIndex, 1);

    const userManageIndex = parsedObject.findIndex(menu => menu.name == 'User Management');

    let afterCreateUserDelete = parsedObject[userManageIndex].sub.filter((item) => { return item.name != "Create User" && item.name != "Cash Agent"});
    // afterCreateUserDelete = afterCreateUserDelete.filter(item => item.name != "Cash Agent");
    
    parsedObject[userManageIndex].sub = [...afterCreateUserDelete];


    localStorage.setItem('role', JSON.stringify(parsedObject));
    // localStorage.setItem('role', (props.menuPermission));





    localStorage.setItem('activeMenu', JSON.stringify(0));
    let tokenType = getSession('token_type');
    let authToken = getSession('authToken');
    let authorization = { Authorization: ` ${tokenType} ${authToken}` };

    axios.post(apiRoute.currency.currencyList, axiosAccount,{headers: authorization})
        .then(res=>{
            if(res.status === 200){
                if(res.data.status === 200){
                    localStorage.setItem('currency', JSON.stringify(res.data.data));
                    res.data.data.map( (val, key)=>{
                        if(val.isBaseCurrency){
                            localStorage.setItem('activeCurrency', val.currencyId);
                        }
                        return 0;
                    })
                }
            }
        });

    let userID = getSession('sessionData').userId;
    let sessionID = getSession('sessionData').sessionId;

    const axiosData = {

        "userId": userID,
        "sessionId": sessionID,
        "configType": 'Notification'

    };

    axiosNotAuth.post(apiRoute.configuration.show, axiosData,{headers: authorization})
        .then(res=>{
            if(res.status === 200){
                if(res.data.status === 200){
                    localStorage.setItem('notification', JSON.stringify(res.data.data[0]));
                    res.data.data.map( (val, key)=>{
                        if(val.isBaseCurrency){
                            localStorage.setItem('activeCurrency', val.currencyId);
                        }
                        return 0;
                    })
                }
            }
        });
 if(getSession('sessionData')){
     return true;
 }else{
     return false;
 }
};

export default createSession;
