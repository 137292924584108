import React , {Component} from "react";
import {Link} from 'react-router-dom';
class OtpVerification extends Component {

    render() {

        return (
            <div className="card">

                <div className="card-body login-card-body">
                    <form action="" method="post" autoComplete="off">
                        <div className="form-group has-feedback">
                            <span className="fas fa-user-shield form-control-feedback"></span>
                            <input onChange={ (e)=> this.props.stateChangeHandler(e, 'otp')}
                                   type="number" className="form-control" placeholder="OTP"/>
                        </div>

                        <div className="row">
                            <div className="col-lg-6 col-10">
                                <p className="mb-1">
                                    <Link to={'/signin'} href="javascript:void(0)">Sign-In now</Link>
                                </p>
                            </div>
                            <div className="col-lg-6 col-10">
                                <button onClick={()=>{if (window.confirm('Are you sure you wish to verify?'))  this.props.verify()}}
                                    type="button" className="btn btn-primary btn-block btn-flat">Verify Now</button>
                            </div>

                        </div>
                    </form>


                </div>

            </div>
        );
    }
}

export default OtpVerification;