import React , {Component} from 'react';
import { Redirect } from 'react-router-dom';
import Auxi from '../../hoc/Auxi';
import LayoutLandingPage from '../../Components/Layout/LayoutLandingPage';
import SignIN from '../../Components/SignIn/SignIn';
import axios from '../../axios';
import apiRoute from '../../apiRoute';
import createSession from '../../Components/UI/Session/createSession';
import Modal from '../../Components/UI/Modal/Modal';
import ModalContent from '../../Components/UI/Modal/ModalContent';
import { css } from 'react-emotion';
import { RingLoader } from 'react-spinners';
import getSession from '../../Components/UI/Session/getSession';
import {successMessage, failedMessage} from '../../Message';

const override = css`
    display: block;
    margin: 300px auto;
    border-color: red;
    z-index: 1`;
const error = {
    usernameError: 'has-error',
    usernameErrorMessage: 'User Name Required',
    passwordError: 'has-error',
    passwordErrorMessage: 'Password Required'
};

class SignInManager extends Component{


    state = {
        loading: false,
        signUpsignUptoggleEye : true,
        signInData : {
            username: '',
            password: ''
        },
        error: {...error},
        userId: '',
        redirectToReferrer: false,
        otpCheckFalse: false,
        axiosStatus: {
            status: '',
            title: '',
            details: '',
        },
        modal: false,
    };

    pageHandler = (data) => {

        if(data.code === '8'){
            this.setState({redirectToReferrer : 'maxAttempt' });
        }else if(data.code === '9'){
            this.setState({redirectToReferrer : 'passwordExpired' });
            sessionStorage.setItem('authData', JSON.stringify(data.data[0]));
        }else if(data.status === 200){
            if(data.data[0].loginEmailAuthentication){
                this.setState({redirectToReferrer : true });
            }else if(createSession(data.data[0])){
                this.setState({redirectToReferrer : 'auth' });
            }

        }


    };


    modalHandler = ()=> {
        this.setState({modal: !this.state.modal});
    };

    axiosStatusHandler = (status, title, details)=>{
        let oldState = this.state.axiosStatus;
        oldState.status = status;
        oldState.title = title;
        oldState.details = details;
        this.setState({axiosStatus: oldState});
    };

    stateChangeHandler = (event, index) =>{


           let newState = {
               ...this.state.signInData[index]
           };

           newState = event.target.value;
           const oldState = {
               ...this.state.signInData
           };

           oldState[index] = newState;
           this.setState({signInData: oldState},
               () => {this.validateHandler(index, newState )} );

    };

    validateHandler= (fieldName, value) => {
        let errorState = this.state.error;

        switch(fieldName){
            case "username":

                if(value.length < 2){
                    errorState.usernameErrorMessage = 'User Name Must Have 2 Character';
                    errorState.usernameError = 'has-error';
                }else{
                    errorState.usernameErrorMessage = '';
                    errorState.usernameError = '';

                }
                break;
            case "password":

                if(value.length < 6){
                    errorState.passwordErrorMessage = 'Password Must Have 6 Character';
                    errorState.passwordError = 'has-error';
                }else{
                    errorState.passwordErrorMessage = '';
                    errorState.passwordError = '';
                }
                break;
            default:
                break;
        }

        this.setState({error: errorState})

    };

    spinnerHandler = ()=>{
      this.setState({loading: !this.state.loading})
    };

    signinHandler = ()=> {

        let tokenType = getSession('token_type');
        let authToken = getSession('authToken');
        let authorization = { Authorization: ` ${tokenType} ${authToken}` };

        this.spinnerHandler();
        axios.post(apiRoute.signIn,this.state.signInData,{headers: authorization}).then(res => {

            if(res.status === 200){
                if(res.data.status === 200){

                    this.setState({modal: true,error: {...error}}, ()=>{this.spinnerHandler()});
                    this.axiosStatusHandler(res.data.status, res.data.title, successMessage.signInSuccess);
                    this.setState({userId: res.data.data[0].userId});
                    this.pageHandler(res.data);
                }else{

                    this.setState({modal: true,error: {...error}}, ()=>{this.spinnerHandler()});
                    this.axiosStatusHandler(res.data.status, res.data.title, res.data.details);
                    this.pageHandler(res.data);
                }
            }else{
                this.spinnerHandler();
                this.setState({modal: true,error: {...error}});
                this.axiosStatusHandler(res.data.status, 'Error', failedMessage.httpError);
            }



        });
    };

    showHidePassword = (event, index) => {
        const doesShowEye = this.state.signUptoggleEye;
        this.setState ( (prevState, props) => {
            return {
                signUptoggleEye: !doesShowEye
            };
        });
    };


    render(){

        let slashClass = '';
        let passwordShow = '';
        if(this.state.signUptoggleEye) {
            slashClass = 'fa fa-fw fa-eye';
            passwordShow = 'text';
        }else{
            slashClass = 'fa fa-fw fa-eye fa-eye-slash';
            passwordShow = 'password';
        }

        if(this.state.loading){
            return(<RingLoader
                className={override}
                sizeUnit={"px"}
                size={150}
                color={'#E23714'}
                loading={this.state.loading}
            />)
        }else if(this.state.redirectToReferrer === 'maxAttempt'){
            const message_body = 'Your Account IS Locked. You Have Exit Maximum Fail Login Attempts. Contact Support.';
            const message_data = 'Your Account ID IS '+this.state.userId;
            const path_message = 'I already have a membership! Sign IN Now';
            const path_name = '/signin';

            const { from } =   { from : {pathname: '/Message',
                state:{from: this.props.location.state,
                    message_body: message_body, message_data: message_data,userID: this.state.userId,
                    path_message: path_message, path_name: path_name}}};
            return <Redirect to={ from }/>

        }else if(this.state.redirectToReferrer === 'passwordExpired'){

            const message_body = 'Your Password Expired. Reset Password Again';
            const message_data = 'Your Account ID IS '+this.state.userId;
            const path_message = 'Reset Password Now';
            const path_name = '/reset-password';


            const { from } =   { from : {pathname: '/Message',
                state:{from: this.props.location.state,
                    message_body: message_body, message_data: message_data,userID: this.state.userId,
                    path_message: path_message, path_name: path_name}}};
            return <Redirect to={ from }/>

        }else if(this.state.redirectToReferrer === 'auth'){
            return <Redirect to={ '/dashboard' }/>
        }else if(this.state.redirectToReferrer){
            const { from } =   { from : {pathname: '/otp', state:{from: this.props.location.state, userID: this.state.userId}}};
            return <Redirect to={ from }/>
        }else{
            return (
                <Auxi>

                    <Modal show={this.state.modal} modalClosed={this.modalHandler}>
                        <ModalContent
                            status = {this.state.axiosStatus.status}
                            title = {this.state.axiosStatus.title}
                            details = {this.state.axiosStatus.details}
                        />
                    </Modal>
                    <LayoutLandingPage  TitleEnd="Sign In" TitleStart="" boxClass="login-box">

                        <SignIN
                            errorMessage = {this.state.error}
                            stateChangeHandler = {this.stateChangeHandler}
                            request={this.signinHandler}
                            slashClass={slashClass}
                            showHidePassword={this.showHidePassword}
                            passwordShow={passwordShow}/>
                    </LayoutLandingPage>
                </Auxi>
            );
        }

    }
}


export default SignInManager;
