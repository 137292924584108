import React , {Component} from 'react';
import Auxi from '../../../hoc/Auxi';
import ApiRoute from '../../../apiRoute';
import ModalImage from '../Modal/ModalImage';
import ModalCashRecharge from '../Modal/ModalImage';
import ModalContentImage from '../Modal/ModalContentImage';
const $ = require('jquery');
$.Datatable = require('datatables.net-select');

class Datatable extends Component{

    constructor(props){
        super(props);
        this.state = {
            axiosStatus: {
                details: '',
            },
            modal: false,
            remarks: '',
            status: '',
            cashstatus: '',
            cashremarks: ''
        };
        this.table = '';
    }

    componentDidMount(){
        this.$el = $(this.el);
       if(this.props.reportType === '16') {

            this.$el = $(this.el);
            this.table = this.$el.DataTable(
                {
                    data: this.props.data,
                    columns: this.props.titles,
                    select: true,
                    "columnDefs": [{
                        "targets": 5,
                        "data": "description",
                        "render": function (data, type, row, meta) {

                            if (row[5] !== null) {
                                let image = ApiRoute.imageUrl + row[5];
                                return '<image src="' + image + '" height="100" width="100" />'
                            } else {
                                return 'No Image Found'
                            }
                        }
                    }]
                }
            );
        }else  if(this.props.reportType === '17' || this.props.reportType === '18') {

            this.$el = $(this.el);
            this.table = this.$el.DataTable(
                {
                    data: this.props.data,
                    columns: this.props.titles,
                    select: true,
                    "columnDefs": [{
                        "visible": false, "targets": 3
                    }]
                }
            );
        }else if(this.props.reportType === 28){
           this.$el = $(this.el);
           this.table = this.$el.DataTable(
               {
                   data: this.props.data,
                   columns: this.props.titles,
                   select: true,
                   "columnDefs": [{
                       "targets": 10,
                       // "render": function (data, type, row, meta) {
                       //     if (row[6] === 'Processing' && row[10] === 1 &&  parseInt(this.props.allowProductExecutionUpdate,10) === 1 ) {
                       //         return '<button class="btn btn-info"> Update Now</button>';
                       //     } else {
                       //         return 'Not Permitted';
                       //     }
                       // }
                   }]
               }
           );
       }else if(this.props.reportType === 29){
           this.$el = $(this.el);
           this.table = this.$el.DataTable(
               {
                   data: this.props.data,
                   columns: this.props.titles,
                   select: true,
                   "columnDefs": [{
                       "targets": 15,
                       // "render": function (data, type, row, meta) {
                       //     if (row[11] === 'Pending' && row[15] === 1 && parseInt(this.props.allowProductExecutionUpdate,10) === 1) {
                       //         return '<button class="btn btn-info"> Pay Now</button>';
                       //     } else {
                       //         return 'Not Permitted';
                       //     }
                       // }
                   }]
               }
           );
       }else{
            this.$el = $(this.el);
            this.$el.DataTable(
                {
                    data: this.props.data,
                    columns: this.props.titles
                }
            );
        }


     if(this.props.reportType === '16'){
            this.table.on('select', (e)=>{
                if(this.table.rows('.selected').data()[0][5] !== null){
                    let image = ApiRoute.imageUrl + this.table.rows('.selected').data()[0][5];
                    let oldState = this.state.axiosStatus;
                    oldState['details'] =  image ;
                    this.setState({axiosStatus: oldState,modal: true });
                }
            });
        }else if(this.props.reportType === '17' || this.props.reportType === '18'){
            this.table.on('select', (e)=>{
                if(this.table.rows('.selected').data()[0][2] !== 0){
                    this.props.downloadSlipStateHandler(e, this.table.rows('.selected').data()[0][0], 1,  this.table.rows('.selected').data()[0][3])
                }
            });

            this.table.on('deselect', (e)=>{
                    this.props.downloadSlipStateHandler(e, '', 0,'' )

            });
        }else if(this.props.reportType === 28){
         this.table.on('select', (e)=>{

             if(this.table.rows('.selected').data()[0][6] === 'Processing' && this.table.rows('.selected').data()[0][10] === 1 && parseInt(this.props.allowProductExecutionUpdate,10) === 1){
                 let oldState = this.state.axiosStatus;
                 oldState['cashtransactionID'] =  this.table.rows('.selected').data()[0][0] ;
                 oldState['cashagentID'] =  this.table.rows('.selected').data()[0][1] ;
                 oldState['cashamount'] =  this.table.rows('.selected').data()[0][3] ;
                 oldState['cashdeductionAmount'] =  this.table.rows('.selected').data()[0][4] ;
                 oldState['cashcommission'] =  this.table.rows('.selected').data()[0][5] ;
                 this.setState({axiosStatus: oldState,modal: true, cashstatus: '' });
             }
         });

     }else if(this.props.reportType === 29){
          this.table.on('select', (e)=>{

              if(this.table.rows('.selected').data()[0][11] === 'Pending' && this.table.rows('.selected').data()[0][15] === 1 && parseInt(this.props.allowProductExecutionUpdate,10) === 1){
                  let oldState = this.state.axiosStatus;
                  oldState['transactionID'] =  this.table.rows('.selected').data()[0][0] ;
                  oldState['agentID'] =  this.table.rows('.selected').data()[0][3] ;
                  oldState['customer'] =  this.table.rows('.selected').data()[0][4] ;
                  oldState['customerName'] =  this.table.rows('.selected').data()[0][5] ;
                  oldState['commission'] =  this.table.rows('.selected').data()[0][10] ;
                  this.setState({axiosStatus: oldState,modal: true, remarks: '', status: '' });
              }
          });

        }

    }

    //Modal Handler Start
    modalHandler = ()=> {
        this.table.rows( { selected: true } ).deselect();
        this.setState({
            modal: false,
            remarks: '',
            status: '',
            cashstatus: '',
            cashremarks:''
        });
    };


    render(){
        if(this.props.reportType === 28) {
            return(
                <Auxi>

                    <ModalImage show={this.state.modal} modalClosed={this.modalHandler}>
                        <div style={{textAlign: 'center'}}>
                            <h2 style={{color: '#161904f7'}}>Transaction ID: {this.state.axiosStatus.cashtransactionID}</h2>
                            <h2 style={{color: '#161904f7'}}>Agent ID:{this.state.axiosStatus.cashagentID}</h2>
                            <h2 style={{color: '#161904f7'}}>Customer ID:{this.state.axiosStatus.cashamount}</h2>
                            <h2 style={{color: '#161904f7'}}>Customer Name:{this.state.axiosStatus.cashdeductionAmount}</h2>
                            <h2 style={{color: '#161904f7'}}>Commission: {this.state.axiosStatus.cashcommission}</h2>
                            <div className="description-block">
                                <span className="form-control-feedback"> Change Status </span>
                            </div>
                            <div  className={`${this.state.cashstatus === '' ? 'description-block has-error' : 'description-block'}`}>

                                <label style={{'paddingRight': '10px', 'paddingLeft': '10px'}}>Success</label>
                                <input value="0" onChange={(event) => {
                                    this.setState({cashstatus: 1})
                                }}
                                       checked = {this.state.cashstatus === 1 }   type="radio" name="activeValueTypeErrorMessage"/>

                                <label style={{'paddingRight': '10px', 'paddingLeft': '10px'}}>Failed</label>
                                <input value="2" onChange={(event) => {
                                    this.setState({cashstatus: 0})
                                }}
                                       checked = {this.state.cashstatus === 0 } type="radio" name="activeValueTypeErrorMessage"/>

                            </div>
                            <div style={{
                                display: 'flex',
                                width: '90%',
                                paddingLeft: '20%'
                            }}>
                                <label className={this.state.cashremarks === '' ? 'has-error' : ''}>Remarks</label>
                                <input type="text" className="form-control"
                                       value={this.state.cashremarks}
                                       onChange={(e)=>{ this.setState({cashremarks: e.target.value})}}/>
                            </div>

                            <button disabled={ (this.state.cashstatus === '' || this.state.cashremarks === '') ? 'disabled': '' }
                                    style={{margin: '5px'}}
                                    onClick={(e)=>{this.state.cashstatus === '' ?
                                        window.alert('Status Required') :
                                        this.modalHandler();
                                        this.props.getReportUpdateHandlerCash(this.state.axiosStatus, this.state.cashstatus);
                                    }} type="button" className="btn btn-success btn-lg">Confirm</button>
                            <button onClick={(e)=>{this.modalHandler()}} type="button" className="btn btn-danger btn-lg">Cancel</button>

                        </div>
                    </ModalImage>

                    <div style={{overflowX: 'auto'}}>
                        <div className="card">
                            <div className="card-body" style={{'overflowX': 'auto'}}>
                                <table className="display" width="100%" ref={el => this.el = el}></table>
                            </div>
                        </div>
                    </div>
                </Auxi>

            )
        }else if(this.props.reportType === 29) {
            return (
                <Auxi>

                    <ModalImage show={this.state.modal} modalClosed={this.modalHandler}>
                        <div style={{textAlign: 'center'}}>
                            <h2 style={{color: '#161904f7'}}>Transaction ID: {this.state.axiosStatus.transactionID}</h2>
                            <h2 style={{color: '#161904f7'}}>Agent ID:{this.state.axiosStatus.agentID}</h2>
                            <h2 style={{color: '#161904f7'}}>Customer ID:{this.state.axiosStatus.customer}</h2>
                            <h2 style={{color: '#161904f7'}}>Customer Name:{this.state.axiosStatus.customerName}</h2>
                            <h2 style={{color: '#161904f7'}}>Commission: {this.state.axiosStatus.commission}</h2>
                            <div className="description-block">
                                <span className="form-control-feedback"> Change Status </span>
                            </div>
                            <div  className={`${this.state.status === '' ? 'description-block has-error' : 'description-block'}`}>

                                <label style={{'paddingRight': '10px', 'paddingLeft': '10px'}}>Success Only</label>
                                <input value="0" onChange={(event) => {
                                    this.setState({status: 2})
                                }}
                                       checked = {this.state.status === 2 }   type="radio" name="activeValueTypeErrorMessage"/>


                                <label style={{'paddingRight': '10px', 'paddingLeft': '10px'}}>Success & Commission</label>
                                <input value="1" onChange={(event) => {
                                    this.setState({status: 1})
                                }}
                                       checked = {this.state.status === 1 }   type="radio" name="activeValueTypeErrorMessage"/>


                                <label style={{'paddingRight': '10px', 'paddingLeft': '10px'}}>Failed</label>
                                <input value="2" onChange={(event) => {
                                    this.setState({status: 0})
                                }}
                                       checked = {this.state.status === 0 }  type="radio" name="activeValueTypeErrorMessage"/>

                            </div>
                            <div style={{
                                display: 'flex',
                                width: '90%',
                                paddingLeft: '20%'
                            }}>
                                <label className={this.state.remarks === '' ? 'has-error' : ''}>Remarks</label>
                                <input type="text" className="form-control"
                                       value={this.state.remarks}
                                       onChange={(e)=>{ this.setState({remarks: e.target.value})}}/>
                            </div>

                            <button disabled={this.state.remarks === '' || this.state.status === '' ? 'disabled': ''} style={{margin: '5px'}} onClick={(e)=>{this.state.status === '' ? window.alert('Status Required') : this.modalHandler();this.props.getReportUpdateHandler(this.state.axiosStatus, this.state.remarks, this.state.status);}} type="button" className="btn btn-success btn-lg">Confirm</button>
                            <button onClick={(e)=>{this.modalHandler()}} type="button" className="btn btn-danger btn-lg">Cancel</button>

                        </div>
                    </ModalImage>

                    <div style={{overflowX: 'auto'}}>
                        <div className="card">
                            <div className="card-body" style={{'overflowX': 'auto'}}>
                                <table className="display" width="100%" ref={el => this.el = el}></table>
                            </div>
                        </div>
                    </div>
                </Auxi>
            );
        }else{
            return (
                <Auxi>

                    <ModalImage show={this.state.modal} modalClosed={this.modalHandler}>
                        <ModalContentImage
                            details={this.state.axiosStatus.details}
                        />
                    </ModalImage>

                    <div style={{overflowX: 'auto'}}>
                        <div className="card">
                            <div className="card-body" style={{'overflowX': 'auto'}}>
                                <table className="display" width="100%" ref={el => this.el = el}></table>
                            </div>
                        </div>
                    </div>
                </Auxi>
            );
        }
    }
}

export default Datatable;