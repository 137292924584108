import React from 'react';
import Auxi from "../../../hoc/Auxi";

const ModalContentInquiry = (props)=>{

    if(props.status === 200 || props.status === 202){
        let content = '';
        if(Array.isArray(props.details)){
            let promoInfo = '';
            if(props.details[3]){
                promoInfo = <Auxi><h4 style={{color: 'rgb(50, 52, 136)'}}>
                    Promo Text: {props.promo.promoText}<br/>
                    <input onChange={(event) => {props.statePromoChangeHandler(event, 'userInput' )}}
                           value={props.promo.userInput}   type="text" className="form-control" placeholder="Phone Number"/>
                    <div className="row" style={{paddingTop: '20px'}}>
                        <div className="col-lg-4 col-6">
                            <button type="button" className="btn btn-primary" onClick={props.savePromo}>Save</button>
                        </div>
                    </div>

                </h4></Auxi>
            }
            content =  <Auxi><h2 style={{color: '#008000'}}>
                {props.details[0]}<br/>
                Mobiles No: {props.details[1]}<br/>
                Pin No: {props.details[2]}<br/>
                {/*
                    Promo : {props.details[3] ? 'Available' : 'Not Available'} <br/>
*/}
            </h2>{promoInfo}<br/><br/></Auxi>
        }else{
            content =  <h2 style={{color: '#008000'}}>{props.details}</h2>
        }
        return (
            <div style={{height: "300px", "overflowY": "scroll"}}>
                <b><h2 style={{color: '#008000'}}>{props.title}</h2></b>
                {content}
            </div>
        )

    }else{
        return (
            <div style={{textAlign: 'center',height: "200px","overflowY": "scroll"}}>
                {/* <h3 style={{color: '#FF0000'}}>Failed</h3>*/}
                <b><h2 style={{color: '#FF0000'}}>{props.title}</h2></b>
                <h2 style={{color: '#FF0000'}}>{props.details}</h2>
            </div>
        )
    }

};

export default ModalContentInquiry;