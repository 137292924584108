import React, {Component} from "react";
import './SignUp.css';
import {Link} from 'react-router-dom';
import IntlTelInput from 'react-bootstrap-intl-tel-input';
declare var $: jQuery;

class SignUp extends Component{


    componentDidMount() {
       this.intlTelInput();
    }

    phoneNumberEventHandler = (event) => {
        let myDiv  = document.getElementsByClassName('selected-dial-code');
        this.props.stateCountryCodeHandler('mobileNo', [myDiv[0].innerText,event.target.value]);
       // this.props.stateCountryCodeHandler('countryCode', myDiv[0].innerText);
    };



    intlTelInput(){

        $("#phone").intlTelInput({
            // allowDropdown: false,
            // autoHideDialCode: false,
            // autoPlaceholder: "off",
            // dropdownContainer: "body",
            // excludeCountries: ["us"],
            // formatOnDisplay: false,
             geoIpLookup: function(callback) {
               $.get("http://ipinfo.io", function() {}, "jsonp").always(function(resp) {
                 var countryCode = (resp && resp.country) ? resp.country : "";
                 callback(countryCode);
               });
             },
            // hiddenInput: "full_number",
            initialCountry: "my",
            //nationalMode: false,
            onlyCountries: ['my','bd'],
            // placeholderNumberType: "MOBILE",
            // preferredCountries: ['cn', 'jp'],
             separateDialCode: true,
             utilsScript: "plugins/flag/js/utils.js"
        });
    }



    render(){

        let btnDisable = '';
        (this.props.errorMessage.termsError ||this.props.errorMessage.passwordError || this.props.errorMessage.usernameError|| this.props.errorMessage.emailError|| this.props.errorMessage.mobileNoError || this.props.errorMessage.captchaError) ? btnDisable = "disabled" : btnDisable = "";

        return (
            <div className="card">
                <div className="card-body register-card-body">


                    <form method="post" autoComplete="off">
                        <div className="row">

                            <div className="col-md-12 col-12">
                                <div className={"form-group "+ this.props.errorMessage.fullNameError ? this.props.errorMessage.fullNameError : '' +"has-feedback"}>
                                    <span className="fa fa-user-circle-o form-control-feedback"></span>
                                    <input onChange={(event) => {this.props.stateChangeHandler(event, 'fullName')}} type="text" className="form-control" placeholder="User Full Name"/>
                                    { <span className="help-block">
                                        <strong> {this.props.errorMessage.fullNameErrorMessage }</strong>
                                            </span> }
                                </div>
                            </div>
                            <div className="col-md-6 col-12">
                                <div className={"form-group "+ this.props.errorMessage.usernameError ? this.props.errorMessage.usernameError : '' +"has-feedback"}>
                                    <span className="fas fa-user form-control-feedback"></span>
                                    <input onChange={(event) => {this.props.stateChangeHandler(event, 'username')}} type="text" className="form-control" placeholder="User Name"/>
                                    { <span className="help-block">
                                        <strong> {this.props.errorMessage.usernameErrorMessage }</strong>
                                            </span> }
                                </div>
                            </div>
                            <div className="col-md-6 col-12">
                                <div className={"form-group "+ this.props.errorMessage.mobileNoError ? this.props.errorMessage.mobileNoError : '' +"has-feedback"}>
                                    <span className="fas fa-phone form-control-feedback"></span>
                                    <input onChange={(event) => {this.phoneNumberEventHandler(event)}} id="phone" type="text" className="form-control" placeholder="Phone"/>
                                    { <span className="help-block">
                                        <strong> {this.props.errorMessage.mobileNoErrorMessage }</strong>
                                            </span> }
                                </div>
                            </div>


                            <div className="col-md-6 col-12">
                                <div className={"form-group "+ this.props.errorMessage.emailError ? this.props.errorMessage.emailError : '' +"has-feedback"}>
                                    <span className="fas fa-envelope form-control-feedback"></span>
                                    <input onChange={(event) => {this.props.stateChangeHandler(event, 'email')}} type="email" className="form-control" placeholder="Email"/>
                                    {<span className="help-block">
                                        <strong> {this.props.errorMessage.emailErrorMessage }</strong>
                                            </span> }
                                </div>
                            </div>

                            <div className="col-md-6 col-12">
                                <div className={"form-group "+ this.props.errorMessage.passwordError ? this.props.errorMessage.passwordError : '' +"has-feedback"}>
                                    <span className="fas fa-lock form-control-feedback"></span>
                                    <input onChange={(event) => {this.props.stateChangeHandler(event, 'password')}} id="pasword" type={this.props.passwordShow} className="form-control" placeholder="Password"/>
                                    <span onClick={this.props.showHidePassword} toggle="#password-field" className={this.props.slashClass+' '+'fieldIcon toggle-password'} ></span>
                                    {<span className="help-block">
                                        <strong> { this.props.errorMessage.passwordErrorMessage }</strong>
                                            </span> }
                                </div>
                            </div>

                            <div className="col-md-6 col-6">
                                <div className="form-group has-feedback">
                                    <span className="fas fa-user-shield form-control-feedback"></span>
                                    <input type="text" className="form-control" id="question" data-captcha={this.props.numberCaptchaValue} value={this.props.numberCaptcha} readOnly />
                                </div>
                            </div>

                            <div className="col-md-6 col-6">
                                <div className={"form-group "+ this.props.errorMessage.captchaError ? this.props.errorMessage.captchaError : '' +"has-feedback"}>
                                    <span className="fas fa-check form-control-feedback"></span>
                                    <input onChange={(event) => {this.props.stateCaptchaChangeHandler(event, 'numberCaptchaInput')}} id="captcha" type="number" className="form-control" placeholder="Number"/>
                                    {<span className="help-block">
                                        <strong> {this.props.errorMessage.captchaErrorMessage }</strong>
                                            </span> }
                                </div>
                            </div>
                        </div>


                        <div className="row" style={{paddingTop: '20px'}}>
                            <div className="col-md-8 col-xs-12">
                                <div className="checkbox icheck">
                                    <label>

                                        <input checked ={this.props.terms === 'on' ? 1 : 0} onChange={(event) => {this.props.stateChangeHandler(event, 'terms')}} type="checkbox"/> I agree to the
                                        <Link to="term-conditions" target="_blank" >Terms</Link>
                                    </label>
                                </div>
                            </div>

                            <div className="col-md-4 col-xs-12">
                                <button onClick={this.props.signupHandler} disabled={btnDisable} type="button" className="btn btn-primary btn-block btn-flat">Register</button>
                            </div>

                        </div>
                    </form>
                    <Link to={'/signin'} className="text-center">I already have a membership</Link>
                </div>

            </div>

        );

    }


}



export default SignUp;