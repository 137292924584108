import React, {Component} from 'react';
import Auxi from '../../hoc/Auxi';
import {  Link } from 'react-router-dom';
import LayoutLandingPage from '../../Components/Layout/LayoutLandingPage';
import axios from '../../axios';
import apiRouter from '../../apiRoute';
import Modal from '../../Components/UI/Modal/Modal';
import ModalContent from '../../Components/UI/Modal/ModalContentInquiry';
import { css } from 'react-emotion';
import { RingLoader } from 'react-spinners';
import getSession from '../../Components/UI/Session/getSession';
import './cssClasses/cssClasses.css';
import {successMessage, failedMessage} from '../../Message';

const override = css`
    display: block;
    margin: 300px auto;
    border-color: red;
    z-index: 1`;


class RechargeManager extends Component{

    constructor(props){
        super(props);
        this.state={
            loading: false,
            rechargeState: 1,
            recharge: {
                "mobileNo": "",
                "pin": "",
                "channel": "web"
            },
            inquiry: {
                "serialNo": "",
                "pin": "",
            },
            promo: {
                transactionId: '',
                mobileNo: '',
                pin: '',
                userInput: ''
            },
            error: {
                userNameError: 'has-error',
                userNameErrorMessage: 'User Name Required',
            },
            redirectToReferrer: false,
            axiosStatus: {
                status: '',
                title: '',
                details: ''
            },
            modal: false
        };
    }



    spinnerHandler = ()=>{
        this.setState({loading: !this.state.loading})
    };

    modalHandler = ()=> {
        this.setState({modal: !this.state.modal});
    };

    axiosStatusHandler = (status, title, details)=>{
        let oldState = this.state.axiosStatus;
        oldState.status = status;
        oldState.title = title;
        oldState.details = details;
        this.setState({axiosStatus: oldState});
    };
    stateRechargeChangeHandler = (e, i)=>{
        let oldState = {...this.state.recharge};
        oldState[i] = e.target.value.trim();
        this.setState({recharge: oldState});
    };
    stateInquiryChangeHandler = (e, i)=>{
        let oldState = {...this.state.inquiry};
        oldState[i] = e.target.value.trim();
        this.setState({inquiry: oldState});
    };
    statePromoChangeHandler = (e, i)=>{
        let oldState = {...this.state.promo};
        oldState[i] = e.target.value;
        this.setState({promo: oldState});
    };

    rechargeHandler = ()=>{
        let tokenType = getSession('token_type');
        let authToken = getSession('authToken');
        let authorization = { Authorization: ` ${tokenType} ${authToken}` };
        if(this.state.recharge.mobileNo.length === 0 ||  this.state.recharge.pin.length === 0){
            this.setState({modal: true},
                this.axiosStatusHandler(404, 'Failed', failedMessage.rechargeNotFound));
        }else {
            this.spinnerHandler();
            axios.post(apiRouter.card.recharge, this.state.recharge,{headers: authorization}).
            then(res=>{
                if(res.status === 200){
                    if(res.data.status === 200){
                        let oldState = this.state.recharge;
                        let mobile = this.state.recharge.mobileNo;
                        let pin = this.state.recharge.pin;
                        oldState['mobileNo'] = '';
                        oldState['pin'] = '';
                        let oldStatePromo = this.state.promo;
                        if(res.data.data[0].isPromo){
                            oldStatePromo['transactionId'] = res.data.data[0].transactionId;
                            oldStatePromo['mobileNo'] = mobile;
                            oldStatePromo['pin'] = pin;
                            oldStatePromo['promoText'] = res.data.data[0].promoInfo.promoText;
                            oldStatePromo['userInput'] = res.data.data[0].promoInfo.promoMaskingText;
                        }else{
                            oldStatePromo['transactionId'] = res.data.data[0].transactionId;
                            oldStatePromo['mobileNo'] = mobile;
                            oldStatePromo['pin'] = pin;
                        }

                        this.setState({modal: true, promo: oldStatePromo,recharge: oldState},
                            this.spinnerHandler(),
                            this.axiosStatusHandler(res.data.status, res.data.title, [successMessage.rechargeSuccess, mobile,pin, res.data.data[0].isPromo]),
                            this.pageHandler(res.data));
                    }else{
                        let oldState = this.state.recharge;
                        oldState['mobileNo'] = '';
                        oldState['pin'] = '';
                        this.setState({modal: true, recharge: oldState},
                            this.spinnerHandler(),
                            this.axiosStatusHandler(res.data.status, res.data.title, res.data.details));
                    }
                }else{
                    let oldState = this.state.recharge;
                    oldState['mobileNo'] = '';
                    oldState['pin'] = '';
                    this.setState({modal: true, recharge: oldState},
                        this.spinnerHandler(),
                        this.axiosStatusHandler(res.data.status, 'Error', failedMessage.httpError));
                }
            });
        }

    };

    savePromo = ()=>{
        let tokenType = getSession('token_type');
        let authToken = getSession('authToken');
        let authorization = { Authorization: ` ${tokenType} ${authToken}` };
        this.spinnerHandler();
        axios.post(apiRouter.card.promo, {

            transactionId: this.state.promo.transactionId,
            mobileNo: this.state.promo.mobileNo,
            pin: this.state.promo.pin,
            userInput: this.state.promo.userInput
        }, {headers: authorization}).then(res => {
            if (res.status === 200) {
                if (res.data.status === 200) {
                    this.setState({modal: true},
                        this.spinnerHandler(),
                        this.axiosStatusHandler(res.data.status, res.data.title, successMessage.promoSuccess),
                        this.pageHandler(res.data));
                } else {
                    let oldState = this.state.recharge;
                    oldState['mobileNo'] = '';
                    oldState['pin'] = '';
                    this.setState({modal: true, recharge: oldState},
                        this.spinnerHandler(),
                        this.axiosStatusHandler(res.data.status, res.data.title, res.data.details));
                }
            } else {
                let oldState = this.state.recharge;
                oldState['mobileNo'] = '';
                oldState['pin'] = '';
                this.setState({modal: true, recharge: oldState},
                    this.spinnerHandler(),
                    this.axiosStatusHandler(res.data.status, 'Error', failedMessage.httpError));
            }
        });
    };

    inquiryHandler = ()=>{
        if(this.state.inquiry.serialNo === '' && this.state.inquiry.pin === ''){
            this.setState({modal: true},
                this.axiosStatusHandler(404, 'Failed', 'Serial No or Pin Required'));
        }else {
            let tokenType = getSession('token_type');
            let authToken = getSession('authToken');
            let authorization = {Authorization: ` ${tokenType} ${authToken}`};
            this.spinnerHandler();
            axios.post(apiRouter.card.status, {
                "serialNo": this.state.inquiry.serialNo === '' ? undefined : this.state.inquiry.serialNo,
                "pin": this.state.inquiry.pin === '' ? undefined : this.state.inquiry.pin,
            }, {headers: authorization}).then(res => {
                if (res.status === 200) {
                    if (res.data.status === 200) {
                        let msg = '';
                        let table =[];

                        res.data.data.map((d,i)=>{

                            Object.keys(d).forEach(function (key, index) {
                                table.push({key: Object.keys(d)[index], value: d[key]});
                            });
                            table.push({key: 'NA', value: 'NA'});
                            return 0;
                        });

                        msg = <table className="table" style={{"fontSize": "small"}}>
                            <thead>
                            </thead>
                            <tbody>

                            {
                                table.map((p,i)=>{
                                    if(p.key === 'NA' && p.value === 'NA'){
                                        return <Auxi key={i}>
                                            <tr>
                                                <td style={{"borderTop": "1px solid #030e1a","padding": ".25rem"}}></td>
                                            </tr>
                                        </Auxi>
                                    }else{
                                        return <Auxi key={i}>
                                            <tr>
                                                <td style={{"borderTop": "1px solid #ffffff","padding": ".25rem"}}>{p.key}: {p.value}</td>
                                            </tr>
                                        </Auxi>
                                    }

                                })
                            }

                            </tbody>
                        </table>;


                        let oldState = this.state.inquiry;
                        oldState['serialNo'] = '';
                        oldState['pin'] = '';

                        this.setState({modal: true, inquiry: oldState},
                            this.spinnerHandler(),
                            this.axiosStatusHandler(res.data.status, res.data.title, msg),
                            this.pageHandler(res.data));
                    } else {

                        let oldState = this.state.inquiry;
                        oldState['serialNo'] = '';
                        oldState['pin'] = '';
                        this.setState({modal: true, inquiry: oldState},
                            this.spinnerHandler(),
                            this.axiosStatusHandler(res.data.status, res.data.title, res.data.details));
                    }
                } else {
                    let oldState = this.state.inquiry;
                    oldState['serialNo'] = '';
                    oldState['pin'] = '';
                    this.setState({modal: true, inquiry: oldState},
                        this.spinnerHandler(),
                        this.axiosStatusHandler(res.data.status, 'Error', failedMessage.httpError));
                }
            }).catch((e)=>{
                this.setState({modal: true},
                    this.spinnerHandler(),
                    this.axiosStatusHandler(500, 'Failed', 'Failed To Parse Data'));
            });
        }
    };

    pageHandler = (data) => {
        if(data.status){
            this.setState({redirectToReferrer : true });
        }
    };

    changeRechargeHandler = (e, i)=>{
        this.axiosStatusHandler('', '', '');
        let recharge= {
            "mobileNo": "",
            "pin": "",
            "channel": "web"
        };
        let inquiry= {
            "serialNo": "",
            "pin": "",
        };
        this.setState({modal: false,rechargeState : i, recharge: recharge, inquiry: inquiry});
    };


    render(){
        if(this.state.loading){
            return(<RingLoader
                className={override}
                sizeUnit={"px"}
                size={150}
                color={'#E23714'}
                loading={this.state.loading}
            />)
        }else if(this.state.rechargeState === 1){
            return (
                <Auxi>
                    <LayoutLandingPage  TitleEnd="Recharge" TitleStart="" boxClass="login-box">
                        <Modal show={this.state.modal} modalClosed={this.modalHandler}>
                            <ModalContent
                                statePromoChangeHandler = {this.statePromoChangeHandler}
                                promo = {this.state.promo}
                                savePromo = {this.savePromo}
                                status = {this.state.axiosStatus.status}
                                title = {this.state.axiosStatus.title}
                                details = {this.state.axiosStatus.details}
                            />
                        </Modal>
                        <div className="card">
                            <div className="card-body login-card-body">

                                <div className="row" style={{paddingTop: '20px'}}>
                                    <div className="col-lg-4 col-6" style={{paddingLeft: '30px'}}>
                                        <button type="button" className="btn btn-outline-info" onClick={(e)=>{this.changeRechargeHandler(e, 0)}}>Inquiry</button>
                                    </div>
                                    <div className="col-lg-4 col-6" style={{paddingLeft: '30px'}}>
                                        <button type="button" className="btn btn-info" onClick={(e)=>{this.changeRechargeHandler(e, 1)}}>Recharge</button>
                                    </div>
                                </div>

                                <form action="" method="post" autoComplete="off">
                                    <div className={"form-group has-feedback"}>
                                        <span className="fas fa-phone-square form-control-feedback"></span>
                                        <input onChange={(event) => {this.stateRechargeChangeHandler(event, 'mobileNo' )}}
                                               value={this.state.recharge.mobileNo}   type="number" className="form-control" placeholder="Phone Number"/>

                                    </div>
                                    <div className={"form-group has-feedback"}>
                                        <span className="fas fa-user-secret form-control-feedback"></span>
                                        <input onChange={(event) => {this.stateRechargeChangeHandler(event, 'pin' )}}
                                               value={this.state.recharge.pin}     type="number" className="form-control" placeholder="Pin Number"/>

                                    </div>

                                    <div className="row" style={{paddingTop: '20px'}}>
                                        <div className="col-lg-4 col-6">
                                            <button type="button" className="btn btn-primary" onClick={this.rechargeHandler}>Send</button>
                                        </div>
                                    </div>
                                </form>

                                <p className={`mb-1 decoration`}>
                                    <Link to={'/signin'} href="javascript:void(0)">Sign-in now</Link>
                                </p>
                            </div>

                        </div>
                    </LayoutLandingPage>
                </Auxi>
            );

        }else{
            return (
                <Auxi>
                    <LayoutLandingPage  TitleEnd="Inquiry" TitleStart="" boxClass="login-box">
                        <Modal show={this.state.modal} modalClosed={this.modalHandler}>
                            <ModalContent
                                status = {this.state.axiosStatus.status}
                                title = {this.state.axiosStatus.title}
                                details = {this.state.axiosStatus.details}
                            />
                        </Modal>
                        <div className="card">
                            <div className="card-body login-card-body">


                                <div className="row" style={{paddingTop: '20px'}}>

                                    <div className="col-lg-4 col-6" style={{paddingLeft: '30px'}}>
                                        <button type="button" className="btn btn-info" onClick={(e)=>{this.changeRechargeHandler(e, 0)}}>Inquiry</button>
                                    </div>
                                    <div className="col-lg-4 col-6" style={{paddingLeft: '30px'}}>
                                        <button type="button" className="btn btn-outline-info" onClick={(e)=>{this.changeRechargeHandler(e, 1)}}>Recharge</button>
                                    </div>


                                </div>

                                <form action="" method="post" autoComplete="off">
                                    <div className={"form-group has-feedback"}>
                                        <span className="fas fa-phone-square form-control-feedback"></span>
                                        <input onChange={(event) => {this.stateInquiryChangeHandler(event, 'serialNo' )}}
                                               value={this.state.inquiry.serialNo} type="number" className="form-control" placeholder="Serial Number"/>

                                    </div>
                                    <div className={"form-group has-feedback"}>
                                        <span className="fas fa-user-secret form-control-feedback"></span>
                                        <input onChange={(event) => {this.stateInquiryChangeHandler(event, 'pin' )}}
                                               value={this.state.inquiry.pin} type="number" className="form-control" placeholder="Pin Number"/>

                                    </div>

                                    <div className="row" style={{paddingTop: '20px'}}>
                                        <div className="col-lg-4 col-6">
                                            <button type="button" className="btn btn-primary" onClick={this.inquiryHandler}>Search</button>
                                        </div>
                                    </div>
                                </form>

                                <p className={`mb-1 decoration`}>
                                    <Link to={'/signin'} href="javascript:void(0)">Sign-in now</Link>
                                </p>
                            </div>

                        </div>
                    </LayoutLandingPage>
                </Auxi>
            );
        }
    }

}

export default RechargeManager;