import React from 'react';

const ModalContent = (props)=>{

    if(props.status === 200 || props.status === 202){
        return (
            <div style={{textAlign: 'center'}}>
             {/*   <h3 style={{color: '#008000'}}>Success</h3>*/}
                <b><h2 style={{color: '#008000'}}>{props.title}</h2></b>
                <h2 style={{color: '#008000'}}>{props.details}</h2>
            </div>
        )

    }else{
        return (
            <div style={{textAlign: 'center'}}>
               {/* <h3 style={{color: '#FF0000'}}>Failed</h3>*/}
                <b><h2 style={{color: '#FF0000'}}>{props.title}</h2></b>
                <h2 style={{color: '#FF0000'}}>{props.details}</h2>
            </div>
            )
    }

};

export default ModalContent;