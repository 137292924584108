import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import queryString from 'query-string';
import Auxi from '../../hoc/Auxi';
import LayoutLandingPage from '../../Components/Layout/LayoutLandingPage';
import ChangePassword from '../../Components/ChangePassword/ChangePassword';
import axios from '../../axios';
import apiRouter from '../../apiRoute';
import Modal from '../../Components/UI/Modal/Modal';
import ModalContent from '../../Components/UI/Modal/ModalContent';
import { css } from 'react-emotion';
import { RingLoader } from 'react-spinners';
import getSession from '../../Components/UI/Session/getSession';
import {successMessage, failedMessage} from '../../Message';

const override = css`
    display: block;
    margin: 300px auto;
    border-color: red;
    z-index: 1`;

class ChangePasswordManager extends Component{

    constructor(props){
        super(props);
        this.parsed = queryString.parse(this.props.location.search);

        this.state = {
            loading: false,
            changePassword: {
                newPassword: '',
                retypePassword: '',
                userId: this.parsed.user_id,
                token: this.parsed.token
            },
            error: {
                newPasswordError: 'has-error',
                newPasswordErrorMessage: 'Field Required',
                retypePasswordError: 'has-error',
                retypePasswordErrorMessage: 'Field Required',
            },
            redirectToReferrer: false,
            axiosStatus: {
                status: '',
                title: '',
                details: ''
            },
            modal: false,
            passwordView: 0,
        };
        this.resetPasswordField = [
            {id:'newPassword',errorGroup:this.state.error.newPasswordError,errorSpan:this.state.error.newPasswordErrorMessage,
                cssClasses:'fa fa-fw fa-eye',toggleEyeStatus: true,fieldType:'password', placeholder:'New Password'},
            {id:'retypePassword',errorGroup:this.state.error.retypePasswordError,errorSpan:this.state.error.retypePasswordErrorMessage,
                cssClasses:'fa fa-fw fa-eye',toggleEyeStatus: true,fieldType:'password', placeholder:'Re-type Password'},
        ]
    };

    axiosStatusHandler = (status, title, details)=>{
        let oldState = this.state.axiosStatus;
        oldState.status = status;
        oldState.title = title;
        oldState.details = details;
        this.setState({axiosStatus: oldState});
    };

    spinnerHandler = ()=>{
        this.setState({loading: !this.state.loading})
    };

    stateChangeHandler = (e, i)=>{
        /*if(i === 'newPassword'){*/
            let oldState = {...this.state.changePassword};
            let newState = e.target.value;
            oldState[i] = newState;
            this.setState({changePassword: oldState},
                ()=>{this.validateHandler(i, newState)});
       /* }else{
            let oldState = {...this.state.changePassword};
            let newState = e.target.value;
            let newState = e.target.value;
            this.setState({changePassword: oldState},
                ()=>this.validateHandler(i, newState));
        }*/

    };


    validateHandler= (fieldName, value) => {
        let errorState = this.state.error;
        switch(fieldName){
            case "newPassword":
                if(value.length < 6){
                    errorState.newPasswordErrorMessage = 'Password Must Have 6 Characters';
                    errorState.newPasswordError = 'has-error';
                }else if(this.state.changePassword.retypePassword !== value && this.state.changePassword.retypePassword !== ''){
                    errorState.newPasswordErrorMessage = 'Password Does Not Match';
                    errorState.newPasswordError = 'has-error';
                }else{
                    errorState.newPasswordErrorMessage = '';
                    errorState.newPasswordError = '';

                }
                break;
            case "retypePassword":

                if(this.state.changePassword.newPassword !== value){
                    errorState.retypePasswordErrorMessage = 'Password Does Not Match';
                    errorState.retypePasswordError = 'has-error';
                }else{
                    errorState.retypePasswordErrorMessage = '';
                    errorState.retypePasswordError = '';

                }
                break;
            default:
                break;
        }

            this.setState({error: errorState});
            this.resetPasswordField[0].errorGroup =  this.state.error.newPasswordError;
            this.resetPasswordField[0].errorSpan = this.state.error.newPasswordErrorMessage;
            this.resetPasswordField[1].errorGroup = this.state.error.retypePasswordError;
            this.resetPasswordField[1].errorSpan = this.state.error.retypePasswordErrorMessage;
    };

    pageHandler = (data) => {
        if(data.status){
            this.setState({redirectToReferrer : true });
        }
    };

    changePasswordHandler = ()=>{
        let tokenType = getSession('token_type');
        let authToken = getSession('authToken');
        let authorization = { Authorization: ` ${tokenType} ${authToken}` };
        this.spinnerHandler();
        axios.post(apiRouter.resetPassword.update, {
            "userId": this.state.changePassword.userId,
            "token": this.state.changePassword.token,
            "newPassword": this.state.changePassword.newPassword
        },{headers: authorization})
            .then(res=>{
            if(res.status === 200){
                if(res.data.status === 200){
                    this.spinnerHandler();
                    this.setState({modal: true});
                    this.axiosStatusHandler(res.data.status, res.data.title, successMessage.changePasswordSuccess);
                    this.pageHandler(res.data);
                }else{
                    this.spinnerHandler();
                    this.setState({modal: true});
                    this.axiosStatusHandler(res.data.status, res.data.title, res.data.details);
                }
            }else{
                this.spinnerHandler();
                this.setState({modal: true});
                this.axiosStatusHandler(res.data.status, 'Error', failedMessage.httpError);
            }
        });
    };

    showHidePassword = (event, index) => {


        const field = {
            ...this.resetPasswordField[index]
        };

        if(field.toggleEyeStatus){
            field.cssClasses = 'fa fa-fw fa-eye fa-eye-slash';
            field.fieldType = 'text';
            field.toggleEyeStatus = !field.toggleEyeStatus;
        } else{
            field.cssClasses = 'fa fa-fw fa-eye';
            field.fieldType = 'password';
            field.toggleEyeStatus = !field.toggleEyeStatus;
        }

        const resetPasswordFields = [...this.resetPasswordField];
        resetPasswordFields[index] = field;
        this.resetPasswordField = resetPasswordFields;
        this.setState({passwordView: !this.state.passwordView});
    };


    modalHandler = ()=> {
        this.setState({modal: !this.state.modal});
    };



    render(){
        if(this.state.loading){
            return(<RingLoader
                className={override}
                sizeUnit={"px"}
                size={150}
                color={'#E23714'}
                loading={this.state.loading}
            />)
        }else if(this.state.redirectToReferrer){
            const message_body = 'Your Password Has Been Updated Successfully';
            const message_data = 'For Any Kind OF Query Call Support';
            const path_message = 'I already have a membership! Sign IN Now';
            const path_name = '/signin';

            const { from } =   { from : {pathname: '/Message',
                state:{from: this.props.location.state,
                    message_body: message_body, message_data: message_data,userID: this.state.userId,
                    path_message: path_message, path_name: path_name}}};
            return <Redirect to={ from }/>;
        }else{
            return (
                <Auxi>
                    <Modal show={this.state.modal} modalClosed={this.modalHandler}>
                        <ModalContent
                            status = {this.state.axiosStatus.status}
                            title = {this.state.axiosStatus.title}
                            details = {this.state.axiosStatus.details}
                        />
                    </Modal>
                    <LayoutLandingPage  TitleEnd="Expired" TitleStart="" boxClass="login-box">
                        <ChangePassword
                            showHidePassword={this.showHidePassword}
                            resetPasswordField={this.resetPasswordField}
                            changePassword={this.changePasswordHandler}
                            errorMessage={this.state.error}
                            stateChangeHandler={this.stateChangeHandler}
                        />

                    </LayoutLandingPage>
                </Auxi>
            );
        }

    }

}

export default ChangePasswordManager;


