

export const successMessage = {
    signInSuccess : 'Successfully Sign In',
    signUpSuccess : 'Successfully Sign Up',
    resetPasswordSuccess : 'Password Reset Successfully',
    otpVerificationSuccess : 'Otp Verified Successfully',
    forgetPasswordSuccess: 'A Verification Email Send To Your Email',
    changePasswordSuccess : 'Password Changed Successfully',
    topUpSaveSuccess: 'Top Up Request Successfully Forwarded',
    sohojRechargeSaveSuccess: 'Top Up Request Successfully Forwarded',
    roleSaveSuccess: 'Role Successfully Saved',
    roleSettingsData : 'Role Data Successfully Fetched',
    roleSettingsDataSave : 'Role Settings Successfully Fetched',
    internetSaveSuccess: 'Internet Balance Request Successfully Forwarded',
    fundTransferSaveSuccess: 'Your fund has been successfully transferred',
    commissionSuccess : 'Commission Successfully Saved',
    configurationSuccess : 'Commission Successfully Saved',
    userCreationSuccess: 'User Successfully Created',
    currencySaveSuccess : 'Currency Successfully Saved',
    userInfoSaveSuccess : 'Information Of User Successfully Saved',
    notificationInfoSaveSuccess : 'Information Of User Successfully Saved',
    purchaseBalanceSuccess : 'Balance Purchase Request Forwarded To User Parent',
    salesCardSelectUserSuccess : 'Selected User Added',
    salesInvoiceCreatedSuccess : 'Sales Invoice Saved Successfully',
    salesDepositSuccess : 'Sales Deposit Successfully Saved',
    fundTransferRequestApproveSuccess : 'Successfully Saved',
    lotDataSaveSuccess : 'Lot Data Saved Successfully',
    resellerCreateSuccess : 'Reseller Successfully Created',
    adminBalanceSuccess : 'Admin Balance Add Successfully. Please verify OTP',
    rechargeSuccess: 'Recharge Successful For',
    promoSuccess: 'Congratulations. Your Promo Has Been Successfully Applied.',
    updateRechargeRequestSuccess: 'Recharge Updated Successfully',
    routeCreateSuccess: 'Route Added Successfully',
    routeUpdateSuccess: 'Route Updated Successfully',
    routeDeleteSuccess: 'Route Deleted Successfully',
    lotModificationSuccess : 'Card Successfully Transferred Waiting For Users Acceptance',
    lotModificationBulkSuccess: 'Number of modified cards are',
    lotProductRelation: 'Lot Product Successfully Created',
    lotUpdateSuccessful: 'Lot Updated Successfully',
    cardTransferSuccess: 'Card Successfully Transfer',
    cardTransferAcceptSuccess: 'Card Successfully Modified',
    productRateCreateSuccess: 'Product Rate Successfully Created',
    productRateUpdateSuccess: 'Product Rate Successfully Updated',
    productSaveSuccess: 'Product Successfully Created',
    packageSuccess: 'Package Successfully Saved'
};

export const failedMessage = {
    httpError: 'Request Failed Due To Server Error',
    signInFailed : 'User Name or Password Does Not Match',
    signUpFailed : 'Expected Information Not Found, Sign Up Failed',
    resetPasswordFailed : 'Password Reset Failed, Your Previous Password Does Not Match',
    otpVerificationFailed : 'Otp Verification Failed',
    forgetPasswordFailed : 'Send Email Could Not Be Send Due To Technical Error',
    changePasswordFailed : 'Password Could Not Be Changed Due To Technical Error',
    topUpProductAuthFailed : 'Failed To Fetch Top Up Product Information',
    topUpPackageAuthFailed : 'Failed To Fetch Top Up Package Information',
    topUpCheckFailed : 'Failed To Check Top Up Information',
    topUpSaveFailed : 'Top Up Request Failed To Forward Due To Technical Error',
    sohsoRechargeCheckFailed : 'Failed To Check Top Up Information',
    sohsoRechargeSaveFailed : 'Top Up Request Failed To Forward Due To Technical Error',
    roleSaveFailed : 'Role Failed To Save Due To Technical Error',
    roleFetchFailed : 'Role Data Failed To Fetch Due To Technical Error',
    reportFailed: 'Report Data Failed To Fetch Due To Technical Error',
    internetProductAuthFailed : 'Failed To Fetch Internet Balance Product Information',
    internetPackageAuthFailed : 'Failed To Fetch Internet Balance Package Information',
    internetCheckFailed : 'Failed To Check Internet Balance Information',
    internetSaveFailed : 'Internet Balance Request Failed To Forward Due To Technical Error',
    fundTransferCheckFailed : 'Failed To Check Fund Transfer Information',
    fundTransferSaveFailed : 'Fund Transfer Request Failed To Forward Due To Technical Error',
    commissionFetchFailed : 'Failed To Fetch Commission Data',
    commissionSaveFailed : 'Commission Failed To Save Due Technical Error',
    configurationFetchFailed : 'Failed To Fetch Commission Data',
    configurationSaveFailed : 'Commission Failed To Save Due Technical Error',
    userCreationFailed : 'Failed To Create User Due To Technical Error',
    currencySaveFailed: 'Fail To save Currency Due To Technical Error',
    currencyFetchFailed: 'Fail To save Currency Due To Technical Error',
    userFetchFailed : 'Failed To Fetch User Data',
    userSaveFailed : 'Failed to Save User Information Due To Technical Error',
    userSelectionError : 'Please First Select Authentication Then Submit For Corresponding Product',
    productSelectionError : 'Probable Amount Can\'t be Empty',
    productReferenceError : 'Reference Not Modified',
    fileUploadFailed : 'Faile To Upload File',
    purchaseBalanceFailed : 'Balance Purchase Failed Due To Technical Error',
    salesCardSelectUserFailed : 'Failed To Fetch User',
    orderRemoved: 'Order Removed ,Order & User Removed',
    salesInvoiceCreatedFailed : 'Sales Invoice Failed To Save Due To Technical Error',
    salesDepositFailed : 'Sales Deposit Successfully Saved',
    fundTransferRequestApproveFailed : 'Failed To Approve Due To Technical Error',
    fetchLotDataFailed : 'Failed To Fetch Lot Data',
    saveLotDataFailed : 'Save Lot Data Error',
    updateRechargeRequestRemarksRequiredError: 'Remarks Required',
    rechargeNotFound: 'Both Field Required',
    selectReportFailed: 'Please Select Report Category',
    cardSearchError: 'Serial Number or Number of Cards Required',
    lotAdditionFailed: 'Lot Already Added',
    cardTransferActionError: 'Please Check From Accept or Reject'
};




