import React , {Component} from "react";
import './SignIn.css';
import {Link} from 'react-router-dom';
import Auxi from "../../hoc/Auxi";
class SignIn extends Component {

    render() {
        let btnDisable = '';
        (this.props.errorMessage.passwordError || this.props.errorMessage.usernameError ) ? btnDisable = "disabled" : btnDisable = "";

        return (
            <Auxi>
            <div className="card">
                <div className="card-body login-card-body">
                    <form action="" method="post" autoComplete="off">
                        <div className={"form-group "+ this.props.errorMessage.usernameError ? this.props.errorMessage.usernameError : '' +"has-feedback"}>
                            <span className="fas fa-envelope form-control-feedback"></span>
                            <input onChange={(event) => {this.props.stateChangeHandler(event, 'username' )}}
                                   type="email" className="form-control" placeholder="User Name"/>

                            {<span className="help-block">
                                        <strong> {this.props.errorMessage.usernameErrorMessage }</strong>
                                            </span> }
                        </div>


                        <div className={"form-group "+ this.props.errorMessage.passwordError ? this.props.errorMessage.passwordError : '' +" has-feedback"}>
                        <span className="fas fa-lock form-control-feedback"></span>
                            <input onChange={(event) => {this.props.stateChangeHandler(event, 'password' )}}
                                   id="pasword" type={this.props.passwordShow} className="form-control"
                                   placeholder="Password"/>
                            <span onClick={this.props.showHidePassword} toggle="#password-field"
                                  className={this.props.slashClass + ' ' + 'fieldIcon toggle-password'}></span>

                            {<span className="help-block">
                                        <strong> {this.props.errorMessage.passwordErrorMessage }</strong>
                                            </span> }

                        </div>

                        <div className="row" style={{paddingTop: '20px'}}>

                             <div className="col-lg-4 col-10">
                                <button type="button" disabled={btnDisable} className="btn btn-primary btn-block btn-flat" onClick={this.props.request}>Sign In</button>
                            </div>

                        </div>
                    </form>


                    <p className="mb-1">
                        <Link to={'/forget-password'}>I forgot my password</Link>
                    </p>
                    <p className="mb-1">
                        <Link to={'/signup'} href="javascript:void(0)">Sign-up now</Link>
                    </p>
                    <p className="mb-1">
                        <Link to={'/supports'} href="javascript:void(0)">Support</Link>
                    </p>
                </div>

            </div>
                <p className={`mb-1 decoration`}>
                    <i className="fa fa-arrow-left" style={{'color': '#007bff'}}></i>
                    <Link to={'/'}  href="javascript:void(0)"> Recharge & Inquiry </Link>
                </p>
            </Auxi>
        );
    }
}

export default SignIn;