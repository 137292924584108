import React from "react";
import {Link} from 'react-router-dom';
import PasswordField from '../UI/PasswordField/PasswordField';
const ChangePassword = (props) => {
    let btnDisable = '';

    (props.errorMessage.newPasswordError || props.errorMessage.retypePasswordError) ? btnDisable = "disabled" : btnDisable = "";


    return (

        <div className="card">
            <div className="card-body login-card-body">
                <form action="" method="post" autoComplete="off">
                    {
                        props.resetPasswordField.map((field, index) => {
                            return  <PasswordField
                                errorGroup = {field.errorGroup}
                                errorSpan = {field.errorSpan}
                                stateHandler = {(event) => {props.stateChangeHandler(event, field.id)} }
                                type={field.fieldType}
                                click ={(event) => {props.showHidePassword(event, index)} }
                                placeHolder = {field.placeholder}
                                classesName = {field.cssClasses}
                                key = {field.id}
                            />
                        })
                    }
                </form>


                <div className="row">
                    <div className="col-8" style={{paddingTop: '20px'}}>
                        <button onClick={props.changePassword} disabled={btnDisable} type="button" className="btn btn-primary btn-block btn-flat">Change Password</button>
                    </div>

                </div>
                <div className="row">
                    <div className="col-lg-12 col-12">
                        <Link to={'/signin'} href="javascript:void(0)">I already have a membership</Link>

                    </div>
                </div>

            </div>

        </div>



    );
};

export default ChangePassword;