import React from 'react';
import {  Link } from 'react-router-dom';

const Message = (props)=>{

        return(
             <div className="card">
                <div className="card-body register-card-body">
                    {props.message_body}<br/>
                    {props.message_data}<br/>
                    <a href="mailto:support@ssd-tech.com">Send Email Now</a><br/>
                    <Link to={props.path_name} className="text-center">{props.path_message}</Link>
                 </div>
             </div>
        )

};

export default Message;