import React, {Component} from 'react';
import './PasswordField.css';
import Auxi from '../../../hoc/Auxi';
class PasswordField extends Component  {

    render() {
        return (
         <Auxi>
            <div></div>
            <div className={"form-group "+ this.props.errorGroup ? this.props.errorGroup : '' +"has-feedback"}>
                <span className="fas fa-lock form-control-feedback"></span>
                <input onChange={this.props.stateHandler} type={this.props.type} className="form-control" placeholder={this.props.placeHolder}/>
                <span onClick={this.props.click} toggle="#password-field"
                      className={this.props.classesName + ' ' + 'fieldIcon toggle-password'}></span>
                {<span className="help-block">
                                        <strong> {this.props.errorSpan }</strong>
                                            </span> }
            </div>
              <div></div>
         </Auxi>
        );
    }
}

export default PasswordField;