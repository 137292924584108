const destroySession = () => {
    localStorage.removeItem('sessionData');
    localStorage.removeItem('role');
    localStorage.removeItem('currency');
    localStorage.removeItem('activeCurrency');
    localStorage.removeItem('activeMenu');
    localStorage.removeItem('activeSubMenu');
    localStorage.removeItem('notification');
    return true;
};

export default destroySession;
