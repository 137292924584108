import React from 'react';
import Auxi from '../../hoc/Auxi';

const ProvacyPolicyManager  = ()=>{

        return(
            <Auxi>
                <div style={{"paddingTop": "5px","paddingLeft": "15px","paddingRight": "10px"}}>

                    <h1 style={{"color": "#0055ff", "textAlign": "center"}}>Privacy Policy of Sohoj App</h1>


                    <p>Sohoj is concerned about your
                        right to privacy. Therefore, Sohoj pledges to be responsible when
                        gathering your personal information and to protect your privacy in every
                        possible way. Although, this Privacy Policy is not a contract and does not
                        create any legal rights, however, it does serve as an expression of Sohoj's
                        commitment to protecting private personal information.</p>
                    <p>It must be borne in mind that Dhaka
                        Gate reserves the right to change, amend and/or vary this Privacy Policy at any
                        time without the consent of the users and will notify users of the same at our
                        website.</p>
                    <p><br />
                        This Privacy notice aims to answer your questions about the sources and kinds
                        of information Sohoj collects, how it is used, when it may be shared with
                        others, and how we safeguard its confidentiality and security. We also provide
                        you with ways to correct the data you provide us and the option to limit our
                        sharing of this data with Sohoj's authorized third parties.</p>
                    <p>This Privacy notice is applicable to
                        all users of Sohoj's website <a href="https://sohoj.my/">sohoj.my</a> regardless of the intention and without limitation to Corporate Sales,
                        Marketing Research, and Customer Relations/Service.</p>
                    <br />
                    <h2 style={{"fontWeight": "bold"}}>Information collection</h2>
                    <br />
                    <p>To ensure that we successfully process your transaction
                        and to effectively manage our business, we collect and maintain personal
                        information about you. We collect customer information from many sources for
                        the purposes of understanding current or new demands, meeting these demands as
                        well as to provide and personalize our services. The means of information collection,
                        among others, are as following:&nbsp;</p>
                    <ul type="disc">
                        <li>From you; when you voluntarily provide to us or to
                            third party service provider(s) engaged by us; </li>
                        <li>From your browser; when you visit our website and your
                            browser interacts with us; </li>
                        <li>From other sources and related links in connection with
                            providing your transportation and/or accommodation needs and services; </li>
                        <li>From you when you interact with us through social media
                        </li>
                    </ul>
                    <p>The personal information we collect
                        about you may include but not limited to the following:</p>
                    <ul type="disc">
                        <li>Name, addresses and telephone numbers </li>
                        <li>Email addresses, fax numbers and mobile numbers or
                            social media accounts </li>
                        <li>Flight information or details </li>
                        <li>Credit / Charge card number(s), including associated
                            billing address(es) and expiration date(s) </li>
                        <li>Information necessary to facilitate travel or other
                            services, including travel companion(s) names, emergency contacts, seating
                            preferences and special dietary or medical needs </li>
                        <li>Passport number, NRIC number, nationality and country
                            of residence </li>
                        <li>Use of products and services such as self-service
                            devices, flight status notification and online check-in </li>
                        <li>Personal information provided via survey, contest,
                            competition or other marketing research efforts </li>
                        <li>Personal information provided to customer-service
                            representatives to research and resolve issues or questions or lodging
                            complain </li>
                        <li>Corporate-contract, employer and/or other corporate
                            affiliation (i.e. employer name, title, address and contact information) </li>
                    </ul>
                    <p>We also collect your information
                        when you make or send job application via our official website <a href="https://sohoj.my/">sohoj.my</a>.</p>
                    <br />
                    <h2 style={{"fontWeight": "bold"}}>Use of information collection</h2>
                    <br />
                    <p>Generally, we use your information
                        for administrative purpose e.g. processing, confirming, fulfilling and
                        completing your transactions and requests for our services. In the event there
                        is alteration / change of transaction, we will use information provided by you
                        our official website <a href="https://sohoj.my/">sohoj.my</a>,
                        our call center, our sales counter, to contact and notify you of the alteration
                        / change. In addition to this, your information will be used for the purposes
                        of accounting (billing and auditing), immigration, custom control, safety,
                        security, statistical and marketing analysis, information systems management,
                        system testing, maintenance and development, operational, support, customer
                        surveys, customer relations and to improve and help us in any future dealings
                        with you, for example by identifying your requirements and preferences.</p>
                    <p>In employment scenario, we would use
                        information that you voluntarily provide to compare against the job
                        specification / criteria we are seeking / intending to employ.</p>
                    <p>Whenever required by law, we will
                        disclose your information to the government bodies or authorities or third
                        parties pursuant to a subpoena or other legal process accordingly. Further, we
                        may also use or disclose your information as permitted by law to protect the
                        rights or property of Sohoj, our customers, our website, or its users. We
                        may also disclose your information, whether in part or in full, to our
                        contracted or authorized companies such as our data processors.</p>
                    <p>As a part of our effort to serve you
                        better, we are constantly enhancing and expanding our services to fulfill your
                        ever-growing needs. In order to realize this business aim, we will from time to
                        time appoint, authorize or contract with strategic business partner. Your
                        information will be disclosed to these business partners, authorized or
                        contracted third party, service providers or advertisers to make available
                        promotions, offers, products or services, which may not belong to us. However,
                        to ensure that you will not receive unwanted communications, only information
                        with regards to promotions, offers, products or services that you selected or
                        marked as interested through our member profile will be shared with the
                        respective business partners, service providers or third parties.</p>
                    <p>In the circumstances set out in this
                        policy where Sohoj passes your information to a third party, we will
                        ensure that the security measures that such party has in place in relation to
                        the processing of your data are at least as stringent as those employed by Dhaka
                        Gate if not better. This does not apply where we are required by law to pass
                        your information to a third party.</p>
                    <p>In the event we go through a
                        business transition, such as sale of part or all of our assets, merger or
                        acquisition, your information will likely form part of this transition and be
                        transferred.</p>
                    <br />
                    <h2 style={{"fontWeight": "bold"}}>Sharing of information collected</h2>
                    <br />
                    <p>Personal information we collect from
                        you may be disclosed to:</p>
                    <ul type="disc">
                        <li>our bank for authorization of transaction and for
                            payment </li>
                        <li>relevant third parties eg. booking agents and hotels </li>
                        <li>authorized government bodies if legally required to do </li>
                        <li>our subsidiaries and affiliates who may be located in
                            or outside Malaysia.</li>
                    </ul>
                    <p>Sohoj does not sell or rent any
                        personal information you provide on our web site to any other parties. Dhaka
                        Gate may share anonymous and/or aggregated information about all our users with
                        third parties.</p>
                    <br />
                    <h2 style={{"fontWeight": "bold"}}>Children under 13 years old</h2>
                    <br />
                    <p>Sohoj does not collect or
                        maintain information directly from those under 13 years old. If you are under
                        13 years old, please do not provide your name and address or other contact
                        details or personal identifiable information of any kind whatsoever.</p>
                    <p>Information of children under the
                        age of 13 would only be collected to facilitate transaction. Such information
                        should only and would normally be provided by the parent or guardian of someone
                        under 13 years old. In addition, only limited information of the child such as
                        name, age, date of birth, traveling document, would be collected by Sohoj.</p>
                    <p>If you are a Parent or Guardian of
                        someone under 13 years old who has provided us information without your
                        knowledge and consent, you may request that we remove this information by
                        contacting us at <span style={{"textDecoration": "underline"}}>website</span>.</p>
                    <br />
                    <h2 style={{"fontWeight": "bold"}}>Access to your personal information</h2>
                    <br />
                    <p>You may access your personal
                        information at our website: <a href="https://sohoj.my/">sohoj.my</a>.</p>
                    <br />
                    <h2 style={{"fontWeight": "bold"}}>Correction/updating personal information</h2>
                    <br />
                    <p>You may correct or update your
                        personal information at our website: <a href="https://sohoj.my/">sohoj.my</a><span>.</span></p>
                    <p>The instructions to do this are also
                        clearly outlined in every piece of promotional material issued by Sohoj.</p>
                    <br />
                    <h2 style={{"fontWeight": "bold"}}>Links to other sites</h2>
                    <br />
                    <p>Sohoj web site contains links
                        to other sites. Please be aware that we are not responsible for the privacy
                        practices or content of those sites. We encourage you to read the privacy
                        statements of each site that collects information from you. Sohoj's
                        privacy policy applies solely to information collected by Sohoj on this
                        web site.</p>
                    <br />
                    <h2 style={{"fontWeight": "bold"}}>Newsletter</h2>
                    <br />
                    <p>By joining the Sohoj email
                        update service, you expressed your agreement for us to contact you via email to
                        inform you of our latest updates, products and services. If you do not wish to
                        remain a subscriber, you are able to remove your details from the subscriptions
                        list at our website.</p>
                    <br />
                    <h2 style={{"fontWeight": "bold"}}>Contests &amp; surveys</h2>
                    <br />
                    <p>From time-to-time Sohoj may
                        provide you the opportunity to participate in surveys or contests on our site.
                        If you participate, we will request certain personally identifiable information
                        from you. Participation in these surveys or contests is completely voluntary
                        and you therefore have a choice whether or not to disclose this information.
                        The requested information typically includes contact information (e.g. name and
                        address).<br />
                        &nbsp;<br />
                        We use this information to update contest participants, notify contest winners,
                        and award prizes and improve the site experience for our Customers where
                        information would be collected in anonymous survey basis.</p>
                    <p>We often use a third party service
                        provider to conduct these surveys or contests; these companies are prohibited
                        from using our users' personally identifiable information for any other
                        purpose. We will not share the personally identifiable information you provide
                        through a contest or survey with other third parties unless we give you prior
                        notice and choice.</p>
                    <br />
                    <h2 style={{"fontWeight": "bold"}}>Security</h2>
                    <br />
                    <p>We use all reasonable endeavors to
                        protect personal information from loss, misuse and alteration. Only authorized
                        employees and agents, who are under appropriate confidentiality obligations,
                        will have access to your personal information. However, you will be responsible
                        for any user ID or password that is used on our web site. You should take due
                        care to protect them.</p>
                    <br />
                    <h2 style={{"fontWeight": "bold"}}>Site tracking</h2>
                    <br />
                    <p>We use tracking software to monitor
                        customer traffic patterns and site usage to help us develop the design and
                        layout of the site to better meet the needs of visitors to sohoj.my. This
                        software does not enable us to capture any personally identifying information.</p>
                    <br />
                    <h2 style={{"fontWeight": "bold"}}>Use of pixels</h2>
                    <br />
                    <p>In order to understand how our
                        customers interact with the emails and the content that we send, we use pixels
                        in the email in order to understand who has opened the message. In order to
                        provide our content in the most interesting way, we may also use pixels to
                        learn whether you can receive emails in text or html form.</p>
                    <br />
                    <h2 style={{"fontWeight": "bold"}}>Log files</h2>
                    <br />
                    <p>We use IP addresses to analyze
                        trends, administer the site, track user's movement, and gather broad
                        demographic information for aggregate use. IP addresses are not linked to
                        personally identifiable information.</p>
                    <br />
                    <h2 style={{"fontWeight": "bold"}}>Your consent</h2>
                    <br />
                    <p>Sohoj or any third party
                        service provides engaged by us will obtain your consent to collect and use your
                        personal information at the time of collection, subject to certain legally
                        prescribed circumstances where your consent is not required.</p>
                    <p>Generally, in using the Sohoj
                        web site, you consent to the collection and use of this information by Dhaka
                        Gate in the ways described above (which may change from time to time) unless
                        and until you inform us to the contrary. , If you have provided us personal
                        information of third party individuals in your travel party, you are required
                        to obtain the individual's prior consent and you represent and warrant that you
                        had or have their consent or are otherwise entitled to provide their personal
                        information to us.</p>
                    <p>In most instances, it is obligatory
                        for you to provide us with your personal information in order to allow us to
                        satisfy your request or provide you with the service that you have requested
                        for. However, where applicable, we will provide you with an avenue to opt-out
                        or unsubscribe from receiving marketing, communications, promotional offers,
                        newsletters or any other communications from Sohoj.</p>
                    <br />
                    <h2 style={{"fontWeight": "bold"}}>Contact information</h2>
                    <br />
                    <p>If you still have questions about our privacy policy, please
                        visit Frequently Asked Questions on our web site where you may submit any
                        questions or complaints you may have. We request your name, phone number, email
                        address and comments so that we may respond to your concerns effectively and
                        work to improve our service. If you have any additional concerns relating to Dhaka
                        Gate's collection and use of your personal information, you may&nbsp;contact <a href="mailto:info@dhakagate.com">info@sohoj.com</a></p>

                            For More ....
                            <a href="http://www.kkmm.gov.my/pdf/Personal%20Data%20Protection%20Act%202010.pdf">PDPA 2010 Malaysia</a>

                </div>
            </Auxi>
        )

};

export default ProvacyPolicyManager;