import React from 'react';
import {Link} from 'react-router-dom';
import Auxi from '../../hoc/Auxi';


const SohojSupportManager = ()=>{
    return (
        <Auxi>

            <h3 style={{"textAlign": "center"}}>We are here to help !</h3>

            <div>
                <div><hr/></div>
                <br/>
            </div>
            <div>
                <br/>
            </div>

            <form>

                <div className="input-area" style={{"textAlign": "center", "fontSize": "20px", "color": "black"}}>
                    <span style={{"color": "darkred", "fontSize": "25px", "fontWeight": "bold"}}> Feel free to contact us anytime :</span><hr/>
                    <div style={{"border": "solid plum", "margin": "10px"}}>
                        <span style={{"color": "blue","textDecoration": "underline"}}>+60163600461</span> <br/>
                        <span style={{"color": "blue", "textDecoration": "underline"}}>+60163600453</span> <br/> <br/>
                    </div>

                    <div style={{"border": "solid plum", "margin": "10px","padding": "10px","textAlign": "start","fontSize": "18px"}}>Our customer support team provides the best service in the industry. We're passionate about our product as well as our customers and it shows in the level of service that we provide.<br/>
                        We're always happy to help find the solution for your needs. If a solution doesn't already exist, we'll create a new solution that resolves your issue.</div> <br/> <br/>


                    <div style={{"border": "solid plum", "margin": "10px"}}>
                        <span style={{"color": "darkred", "fontSize": "22px", "fontWeight": "bold"}}> Bank Details :</span><hr/>
                        <span style={{"color": "blue"}}><span style={{"color": "black"}}>Bank</span> : MALAYAN BANKING BERHAD (MAYBANK)</span> <br/>
                        <span style={{"color": "blue"}}><span style={{"color": "black"}}>Account Name</span> : DOTLINES SDN BHD</span> <br/>
                        <span style={{"color": "blue"}}><span style={{"color": "black"}}>Account No</span> : 564418607604</span> <br/>
                        {/*<span style={{"color": "blue"}}><span style={{"color": "black"}}>Address</span> : MAYBANK, TECHNOLOGY PARK MALAYSIA, </span> <br/>
                                        <span style={{"color": "blue"}}>LOT NO.G1 & G2, GROUND FLOOR,</span> <br/>
                                        <span style={{"color": "blue"}}>SUPPORT SERVICE BUILDING,</span> <br/>
                                        <span style={{"color": "blue"}}>BUKIT JALIL, 57000 KUALA LUMPUR, MALAYSIA.</span> <br/>*/}

                    </div>

                </div>


            </form>
            <div style={{"textAlign": "center"}}>
                <Link to={'/signin'} className="text-center">I already have a membership</Link>
            </div>
        </Auxi>
    )
};
export default SohojSupportManager;