import React,{Component} from 'react';
import sessionData from '../../Components/UI/Session/getSession';
class NoRouteFoundManager extends Component {
    stateHandler = (e)=>{
        localStorage.setItem('activeSubMenu', 0);
        localStorage.setItem('activeMenu', '0');
    };

    render(){
        let path = '';
        if(sessionData('sessionData')){
             path = '/dashboard'
        }else{
             path = '/';
        }
        return(
            <div className="login-logo">
                <div className="card card-danger" style={{"display": "-webkit-inline-box","marginTop" : "20px"}}>
                    <div className="card-header" >
                        <h3 className="card-title">404 !!! No Found</h3>
                    </div>
                </div>
                <h1>Page Not Found</h1>
                <i className="fa fa-arrow-left" style={{'color': '#007bff'}}></i>
                <a onClick={(e)=>{this.stateHandler(e)}} href={path} className = 'text-center'> GO BACK</a>
                <h2 style={{color: "red"}} className="card-title">!!! We Don't Allow Typing URL. Please Use Sohoj Portal !!!</h2>
            </div>
        );
    }
}

export default NoRouteFoundManager;