import React , {Component} from 'react';
import Datatable from '../../Components/UI/Datatable/Datatable';
import ReactExport from "react-data-export";
import queryString from 'query-string';
import '../Home/css/custom.css';
import {CSVLink} from 'react-csv';
import { css } from 'react-emotion';
import { RingLoader } from 'react-spinners';
import { Redirect } from 'react-router-dom';
import axios from '../../axiosBot';
import apiRoute from '../../apiRoute';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import momentTZ from 'moment-timezone';
import getSession from '../../Components/UI/Session/getSession';
import Auxi from "../../hoc/Auxi";
import Modal from '../../Components/UI/Modal/Modal';
import ModalContent from '../../Components/UI/Modal/ModalContent';
import { failedMessage} from '../../Message';
import destroySession from '../../Components/UI/Session/destroySession';

import LayoutBot from '../../Components/Layout/LayoutBot';

require("react-datepicker/dist/react-datepicker-cssmodules.css");
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;


const override = css`
    display: block;
    margin: 300px auto;
    border-color: red;
    z-index: 1`;
class BotReportManager extends Component{

    constructor(props){
        super(props);
        this.parsed = queryString.parse(this.props.location.search);
        this.tokenType = getSession('token_type');
        this.authToken = getSession('authToken');
        this.authorization = { Authorization: ` ${this.tokenType} ${this.authToken}` };
        this.state = {
            userId: this.parsed.userId,
            password: this.parsed.password,
            timezone: momentTZ.tz.guess(),
            filters:{
                startDate: moment(),
                endDate: moment().add(1, 'days'),
                type: parseInt(this.parsed.type, 10),
                reportName : parseInt(this.parsed.type, 10) === 1 ? 'Flexiload Report' : parseInt(this.parsed.type, 10) === 2 ? 'Fund Transfer' : parseInt(this.parsed.type, 10) === 3 ? 'Balance Received History' : parseInt(this.parsed.type, 10) === 4 ? 'Internet Report' : 'Sohoj Recharge',
                reportPreviewCount: 0,
                reportedUserId: this.parsed.userId,
                userType: '0',
            },
            dataSetExcel : '',
            dataSetCSV : '',
            titleDatatable : [],
            dataSetDataable : [],
            axiosStatus: {
                status: '',
                title: '',
                details: '',
                data: ''
            },
            modal: false,
            loading: false,
        };

        this.handleChangeDate = this.handleChangeDate.bind(this);
        this.getReportHandler();
    }

    componentDidMount(){
        document.title = this.state.filters.reportName
    }
    //Modal Handler Start
    modalHandler = ()=> {
        this.setState({modal: false});
    };
    //Modal Handler End

    //Spinner Handler Start
    spinnerHandler = ()=>{
        this.setState({loading: !this.state.loading})
    };
    //Spinner Handler End

    //Axios Alert Handler Start
    axiosStatusHandler = (status, title, details, data)=>{
        let oldState = this.state.axiosStatus;
        oldState.status = status;
        oldState.title = title;
        oldState.details = details;
        oldState.data = data;
        this.setState({axiosStatus: oldState});
    };
    //Axios Alert Handler End

    handleChangeDate(date, dateType) {
        let oldState = this.state.filters;

        if(dateType === 'startDate'){
            oldState['startDate'] = date;
            oldState['reportPreviewCount'] = 0;

        }else{
            oldState['endDate'] = date;
            oldState['reportPreviewCount'] = 0;
        }
        this.setState({filters: oldState});
    }


    getReportHandler = () =>{

           // this.spinnerHandler();
            axios.post(apiRoute.botReport, {
                userId: this.state.userId,
                password: this.state.password,
                type: this.state.filters.type,
                startDate: this.state.filters.startDate.format("YYYY-MM-DD"),
                endDate: this.state.filters.endDate.format("YYYY-MM-DD"),
                timezone: this.state.timezone,
            },{headers: this.authorization}).then(res => {
                if (res.status === 200) {
                    if (res.data.status === 200) {

                        let reportCount = this.state.filters.reportPreviewCount;
                        let title = [];
                        let dataCSV = [];
                        let dataSet = res.data.data[0].values;
                        res.data.data[0].fields.map((field, index) => {
                            let dummyData = {};
                            dummyData.title = field;
                            dummyData.sTitle = field;
                            dummyData.label = field;
                            dummyData.key = field;
                            title.push(dummyData);
                            return 0;
                        });


                        res.data.data[0].values.map((field, index) => {
                            let dummyData = {};
                            field.map((data, i) => {
                                dummyData[title[i].title] = data;
                                return 0;
                            });
                            dataCSV.push(dummyData);
                            return 0;
                        });

                        let excelPreview = [
                            {
                                columns: title,
                                data: dataSet
                            }
                        ];

                        let oldState = this.state.filters;
                        oldState['reportPreviewCount'] = reportCount + 1;
                        this.setState({
                            filters: oldState,
                            dataSetExcel: excelPreview,
                            dataSetCSV: dataCSV,
                            titleDatatable: title,
                            dataSetDataable: dataSet
                        });


                    } else {
                        this.axiosStatusHandler(res.data.status, res.data.title, res.data.details, '');
                        this.setState({modal: true});

                        if (res.data.code === '12')
                            setTimeout(() => {
                                this.setState({redirectToReferrer: 2});
                            }, 500);
                    }
                } else {
                    this.axiosStatusHandler(res.data.status, 'Error', failedMessage.httpError, '');
                    this.setState({modal: true});
                }
               // this.spinnerHandler();
            });


    };



    render(){

        if(this.state.loading){
            return(<RingLoader
                className={override}
                sizeUnit={"px"}
                size={150}
                color={'#E23714'}
                loading={this.state.loading}
            />)
        }else if(this.state.redirectToReferrer === 2) {
            if (destroySession())
                return <Redirect to={'/signin'}/>

        }else {

            return (
                <Auxi>
                    <LayoutBot>

                    <Modal show={this.state.modal} modalClosed={this.modalHandler}>
                        <ModalContent
                            status={this.state.axiosStatus.status}
                            title={this.state.axiosStatus.title}
                            details={this.state.axiosStatus.details}
                        />
                    </Modal>

                    <div style={{'margin': '10px'}}>


                        <div className="row" style={{padding: '20px'}}>
                            <div className="col-md-1"> <span>Start Date</span></div>
                            <div className="col-md-2 col-11">
                                <DatePicker
                                    selected={this.state.filters.startDate}
                                    onChange={(date) => this.handleChangeDate(date, 'startDate')}
                                    dateFormat="YYYY-MM-DD"
                                    className="form-control"
                                    onChangeRaw={(e) => e.preventDefault()}
                                    maxDate={moment()}
                                    withPortal
                                />
                            </div>
                            <div className="col-md-1"> <span>End Date</span></div>
                            <div className="col-md-2 col-11">
                                <DatePicker
                                    selected={this.state.filters.endDate}
                                    onChange={(date) => this.handleChangeDate(date, 'endDate')}
                                    dateFormat="YYYY-MM-DD"
                                    className="form-control"
                                    onChangeRaw={(e) => e.preventDefault()}
                                    maxDate={moment()}
                                    withPortal
                                />
                            </div>



                                <div className="col-md-1 col-4" style={{'marginTop' : '10px'}}>
                                    <button onClick={(e) => {
                                        this.getReportHandler()
                                    }} className={'btn btn-dark'}>Search
                                    </button>
                                </div>
                               {/* { (this.state.filters.reportPreviewCount > 0 && this.state.dataSetDataable.length > 0) ?
                                    <div className="col-md-1 col-4">
                                        <ExcelFile element={<button className={'btn btn-success'}> Excel</button>}
                                                   filename={'Download'} fileExtension={'xlsx'}>
                                            <ExcelSheet dataSet={this.state.dataSetExcel} name="Organization"/>
                                        </ExcelFile>
                                    </div>
                                    : ''}*/}

                                {( this.state.filters.reportPreviewCount > 0 && this.state.dataSetDataable.length > 0 )?
                                    <div className="col-md-1 col-4" style={{'marginTop' : '10px'}}>
                                        <CSVLink data={this.state.dataSetCSV}
                                                 headers={this.headers}
                                                 filename={"my-file.csv"}
                                                 className="btn btn-primary">
                                            CSV
                                        </CSVLink>
                                    </div>
                                    : ''}

                        </div>



                      {/*  <div className="card card-success">
                            <div className="card-header">
                                <h3 className="card-title" style={{'fontSize': '0.75rem'}}>Selected Report Name : {this.state.filters.reportName}</h3>
                            </div>
                        </div>
                        <h3>{this.state.filters.reportPreviewCount > 0 ? 'Search Count : ' + this.state.filters.reportPreviewCount : 'Please Search To Preview  Report'}</h3>*/}
                        {
                            this.state.loading ?
                                <Auxi>
                                    <div style={{overflowX: 'auto'}}>
                                        <div className="card">
                                            <div className="card-body" style={{'overflowX': 'auto'}}>
                                                <RingLoader
                                                    className={override}
                                                    sizeUnit={"px"}
                                                    size={150}
                                                    color={'#E23714'}
                                                    loading={this.state.loading}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </Auxi>
                                :
                                this.state.filters.reportPreviewCount > 0 && this.state.titleDatatable.length > 0?
                                    <Datatable titles={this.state.titleDatatable}
                                               reportType={this.state.filters.type}
                                               data={this.state.dataSetDataable}
                                    ></Datatable> :
                                    ''
                        }
                    </div>
                    </LayoutBot>
                </Auxi>
            );
        }


    }

}

export default BotReportManager;
