import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Auth from './Auth';
import destroySession from '../Components/UI/Session/destroySession';
const PrivateRouteAuth = ({component: Component, ...rest}) => {
    console.log(Auth(rest.path));
    if(Auth(rest.path) === false){
        destroySession();
    }

    return (
        <Route {...rest} render={(props) => (
            Auth(rest.path) === true
                ? <Component {...props} />
                : Auth(rest.path) === 'fake' ? <Redirect to={{
                    pathname: '/dashboard',
                    state: {from: props.location}
                }}/> : <Redirect to={{
                    pathname: '/signin',
                    state: {from: props.location}
                }}/>
        )}/>
    )
}

export default PrivateRouteAuth;