import React, {Component} from 'react';
import Auxi from '../../hoc/Auxi';
import LayoutLandingPage from '../../Components/Layout/LayoutLandingPage';
import Message from '../../Components/UI/Messages/Message';

class MessageHandler extends Component{

    render(){

        return(
        <Auxi>
            <LayoutLandingPage TitleEnd="Message" TitleStart="" boxClass="register-box">
                <Message
                    message_body={this.props.location.state.message_body}
                    message_data={this.props.location.state.message_data}
                    path_message={this.props.location.state.path_message}
                    path_name={this.props.location.state.path_name}
                    userID={this.props.location.state.userID}
                />
            </LayoutLandingPage>
        </Auxi>
        )
    }
}

export default MessageHandler;
