import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import NoAuth from './NoAuth';
const PrivateRouteNotAuth = ({component: Component, ...rest}) => {
    if(NoAuth() === true){
        localStorage.setItem('activeMenu','0');
        localStorage.removeItem('activeSubMenu');
    }
    return (

        <Route {...rest} render={(props) => (
            NoAuth() !== true
                ? <Component {...props} />
                : <Redirect to='/dashboard'/>
        )}/>
    );
};

export default PrivateRouteNotAuth;